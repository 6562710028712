import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import { Helmet } from 'react-helmet'
import TestimonialsPageSlider from '../component/TestimonialsPageSlider/TestimonialsPageSlider'
import SocialMediaFeed from '../component/SocialMediaFeed/SocialMediaFeed'
import Footer from '../component/Footer/Footer'

function Testimonial() {
  return (
    <>
    <Helmet>
          <title> 
          
          </title>
          <meta 
          name='description' 
          content="" 
          />
          
        </Helmet>
        
      
      <HeroBanner
      subHeading=""
      Heading="Testimonials"
      nextLineSpanHeading=""
      Paragraph=""
      bgImg="/images/creatives/testimonial-hero-bg.jpg"
      // headingBgImg="/images/icons/scratch-hero-bg.svg"
      btn={false}
      sideLinks={false}
      bgVideo={false}
      // bgColor="#B1B6AC"
      bgImgLg="/images/creatives/testimonial-hero-bg-mob.jpg"
      smallHeading={true}
      />

        <TestimonialsPageSlider/>

         <SocialMediaFeed/>

        <div style={{transform:"translateY(-25px)"}}>
        <Footer/>
        </div>
      </>
  )
}

export default Testimonial