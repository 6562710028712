import React from 'react'
import Footer from '../component/Footer/Footer'
import HeroBanner from '../component/Common Banner Component/HeroBanner'

function CookiePolicy() {
  return (
    <div>
      <HeroBanner
        subHeading=""
        Heading="Cookie Policy"
        nextLineSpanHeading=""
        Paragraph=""
        bgImg="/images/creatives/plane-hero-bg-light.png"
        btn={false}
        sideLinks={false}
        bgColor=""
      />
      <div className="cookie-policy-container container mt-5">
        <div class="elementor-widget-container">
          <p className='body-paragraph'>
            Our website uses cookies. By using our website and agreeing to this
            policy, you consent to our use of cookies, web beacons, and similar
            technologies following the terms of this policy.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>
              What are cookies, Similar TECHNOLOGIES, and web beacons?
            </strong>
          </p>
          <p className='body-paragraph'>
            A “cookie” is a text file placed on your device’s internal memory
            when you visit our website which helps record how you navigate our
            website. Cookies allow our website to recognize a particular device
            or browser and help us present you with tailored options based on
            the information stored from your previous visit.
          </p>
          <p className='body-paragraph'>
            Cookies do not contain any information that personally identifies
            you and it doesn’t harm your device in any way.
          </p>
          <p className='body-paragraph'>
            Similar technologies are technologies that are used to store
            information in your browser or device’s internal storage, such as
            HTML 5 cookies, flash cookies, and other web software.
          </p>
          <p className='body-paragraph'>
            Web beacons are small files (also called “pixels”, “image tags”, or
            “script tags”) that may be loaded on our site, that may work in
            concert with cookies to identify our users and provide anonymized
            data on their behavior.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>
              Our Use of Cookies, Similar TECHNOLOGIES, and web beacons
            </strong>
          </p>
          <ol>
            <li className='body-paragraph'>
              Identify your Device. When you visit the website or use our mobile
              app, your OS will be recognized, so our service can recommend a
              corresponding software version for you.
            </li>
            <li className='body-paragraph'>
              Preferences and Settings Storage.&nbsp; Settings that allow our
              services to function correctly or that save your preferences over
              time may be stored on your device.&nbsp;We also save preferences,
              such as language, browser, and others, so those do not have to be
              reset each time you return to the site.
            </li>
            <li className='body-paragraph'>
              Security. We use cookies to detect fraudulent activities and abuse
              of our websites and services.
            </li>
            <li className='body-paragraph'>
              Storage of Information you provide. Your email address, feedbacks,
              and other information you offered when using our services.
            </li>
            <li className='body-paragraph'>
              Social Media. Our website may include social media cookies that
              enable users who are logged in to the social media service to
              share content via that service.
            </li>
            <li className='body-paragraph'>
              Advertising. We use cookies to record how many visitors have
              clicked on an advertisement.
            </li>
            <li className='body-paragraph'>
              Performance. We use cookies for load balancing to ensure that
              websites remain up and running.
            </li>
            <li className='body-paragraph'>
              Analytics. To provide you with our services, cookies, and other
              identifiers are used to gather usage and performance data. For
              example, we make use of cookies to determine the number of unique
              visitors to each web page or service used and to develop other
              statistics about the operations of our services. This includes
              cookies from us and third-party analytics providers.
            </li>
          </ol>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>TYPES OF COOKIES</strong>
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Session cookies </strong>
          </p>
          <p className='body-paragraph'>
            This type of cookie is only stored on your device during a browsing
            session and are automatically erased from your device once you close
            your browser – they usually store an anonymous session ID allowing
            you to use our app or browse a website without having to log in to
            each page, but they do not collect any personal data from your
            device
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Persistent cookies</strong>
          </p>
          <p className='body-paragraph'>
            This type of cookie is stored on your device as a file and remains
            on your device after a browsing session or when you close your
            browser. The cookie can only be read by the website that created it
            when you visit that website again. [Persistent cookies are used for
            Google Analytics.]
          </p>
          <p className='body-paragraph'>
            <em>Cookies can also be classified as follows:</em>
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Strictly necessary cookies </strong>
          </p>
          <p className='body-paragraph'>
            These are essential cookies to enable you to use our website
            effectively, such as when buying a service, and therefore cannot be
            restricted. Without these cookies, our services may not be available
            to you.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Performance cookies </strong>
          </p>
          <p className='body-paragraph'>
            Such cookies allow us to monitor and improve our app or website
            performance. For example, they allow us to evaluate the number of
            visits, identify sources of traffic and see which parts of the site
            are most visited.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Functionality cookies</strong>
          </p>
          <p className='body-paragraph'>
            These cookies allow our website to remember your preferences and
            provide improved features. For instance, we may be able to provide
            you with information or updates relevant to the services you use.
            The type of information collected by these cookies is usually
            anonymized.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>How to block or delete cookies</strong>
          </p>
          <p className='body-paragraph'>
            You can check or change the type of cookies you accept within your
            browser or device setting at any time. You can also restrict all
            cookies at any time through your browser or device settings.
            However, if you restrict cookies on your device (including essential
            cookies) you may not be able to make use of our website.
          </p>
          <p className='body-paragraph'>
            Please note that some third parties who advertise on our website
            (including, for example, providers of external services like web
            traffic analysis services and advertising networks) may also use
            cookies, over which we have no control. These cookies are likely to
            be performance/analytical cookies or targeted cookies.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Google Analytics</strong>
          </p>
          <p className='body-paragraph'>
            Google Analytics is a web analytics service provided by Google, Inc.
            (“Google”). Google uses cookies to help us analyze how users use the
            website. The information generated by the cookie about your use of
            the website (including your IP address) will be transmitted to and
            stored by Google on servers in the United States. In case of
            activation of the IP anonymization, Google will truncate/anonymize
            the last octet of the IP address for Member States of the European
            Union as well as for other parties to the Agreement on the European
            Economic Area. Only in exceptional cases, the full IP address is
            sent to and shortened by Google servers in the USA. On behalf of the
            website, Google will use this information for the purpose of
            evaluating your use of the website, compiling reports on your
            activity for us and third parties who help operate and provide
            services related to the website. Google will not associate your IP
            address with any other data held by Google. You may refuse the use
            of these cookies by selecting the appropriate settings on your
            browser as discussed in this policy. However, please note that if
            you do this, you may not be able to use the full functionality of
            the website. Furthermore, you can prevent Google’s collection and
            use of data (cookies and IP address) by downloading and installing
            the browser plug-in available under
            https://tools.google.com/dlpage/gaoptout?hl=en-US.
          </p>
          <p className='body-paragraph'>
            Further information concerning the terms and conditions of use and
            data privacy can be found at
            http://www.google.com/analytics/terms/us.html.
          </p>{" "}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CookiePolicy