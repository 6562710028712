import React from 'react'
import "./EGiftCard.css"

function EGiftCard() {
  return (
    <div className='EGiftCard-container py-3 '>
        <div className='container text-center'>
            <h3 className='my-3 mt-4 font-Aervish stylish-font-text ' style={{fontSize:"78px"}}>Click Here</h3>
            <h3 className='body-heading mx-auto mb-0' style={{textTransform:"none"}}>for eGift Cards</h3>

            <img 
                src='/images/creatives/egift-card.webp'            
                alt='gift card'
                className='img-fluid gift-card-img'
                style={{cursor:"pointer"}}
                onClick={()=>{
                  window.open("https://squareup.com/gift/ML79QHKZ5ZA9X/order")
                }}
            />

            <p className='text-center ' style={{color:"black",fontSize:"20px"}}>FOR PERSONALIZED VOUCHERS PLEASE EMAIL <br></br>info@cupreadingswc.com</p>
        </div>
    </div>
  )
}

export default EGiftCard