import React from 'react'
import "./ServiceSplitContentSecond.css"

function ServiceSplitContentSecond({
    bgImg = null,
    heading = "",
    headingBg = null,
    content1 = "",
    content2 = "",
    stylishHeading = "",
    splitText = null,
    id = "",
    bgColor=null,
    textColor=null
}) {
    const paragraphsOne = content1.split('\n').map((paragraph, index) => <p key={index} className={`body-paragraph my-3 mx-auto`} style={{color:`${textColor ? textColor:""}`}}>{paragraph}</p>);
    const paragraphsTwo = content2.split('\n').map((paragraph, index) => <p key={index} className={`body-paragraph my-3 mx-auto`} style={{color:`${textColor ? textColor:""}`}}>{paragraph}</p>);
    return (
        <div
        className='ServiceSplitContentSecond-container standard-padding-space pt-0 pt-lg-4'
        style={{ backgroundImage: `url("${bgImg}")`,backgroundColor:`${bgColor?bgColor:"transparent"}` }}
        id={id}
    >
        <div
            className='container mt-5 text-start'>
            <div className='row align-items-center'>
                <div className='col-lg-4 px-0' >
                    <img src='/images/creatives/services-split-second-section.jpg' alt='service side' className='img-fluid w-100 side-img' />
                </div>
                <div className='col-lg-8 px-5 py-4 main-card' style={{border:"7px solid #F6EFEE"}}>
                <h3
                    className='body-heading mb-3 '
                    style={{color:`${textColor ? textColor:""}`}}
                >
                    {heading}
                </h3>
                <p className='body-paragraph my-3 main-par'  style={{color:`${textColor ? textColor:""}`}}>{paragraphsOne}</p>

                {
                    stylishHeading &&
                    <h3 className='my-4 font-Aervish stylish-font-text py-1' style={{color:`${textColor ? textColor:"#B1B6AC"}`}}>{stylishHeading}</h3>
                }

                {
                    splitText &&
                    <div className='row text-align-start'>
                        {
                            splitText.map((ele) => {
                                return (
                                    <div className='col-12 text-start'>
                                        <h3 className='body-heading' style={{ fontSize: "25px",color:`${textColor ? textColor:""}` }}>{ele.title}</h3>
                                        <p className='body-paragraph' style={{color:`${textColor ? textColor:""}`}}>{ele.content}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                <div className='line-seprator mt-2' style={{
                    borderTop:`1px solid ${textColor ? textColor:"black"}`
                }}></div>
                <p className='body-paragraph mt-4 main-para' style={{color:`${textColor ? textColor:""}`}}>{paragraphsTwo}</p>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ServiceSplitContentSecond