import React from 'react'
import "./WorkIndustry.css"
import IndustryCard from './IndustryCard'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"
import { useNavigate } from 'react-router-dom';

const industryData = [
  { img: "/images/icons/bird-icon.svg", title: "Listening to birds singing" },
  { img: "/images/icons/bowl-icon.svg", title: "Lorem" },
  { img: "/images/icons/hand-icon.svg", title: "Lorem" },
  { img: "/images/icons/sun-icon.svg", title: "Colorful Sunset" },
  { img: "/images/icons/bonding-icon.svg", title: "Lorem" },
  { img: "/images/icons/cup-icon.svg", title: "Slow mornings" },
  { img: "/images/icons/sleep-icon.svg", title: "A good night's sleep" },
  { img: "/images/icons/tree-icon.svg", title: "Long walks" },
  { img: "/images/icons/book-icon.svg", title: "A good book" },
  { img: "/images/icons/fire-icon.svg", title: "Ability to freely express yourself" },
  { img: "/images/icons/palet-icon.svg", title: "Lorem" },
  { img: "/images/icons/sleepy-icon.svg", title: "Day Naps" }
]



function WorkIndustry({
  industryList=true
}) {
    const navigate = useNavigate()
    const handlePrevButtonClickInd = () => {
        const swiperInstance = document.querySelector('#ind-cards-slider').swiper;
        swiperInstance.slidePrev();
      };
      
      const handleNextButtonClickInd = () => {
        const swiperInstance = document.querySelector('#ind-cards-slider').swiper;
        swiperInstance.slideNext();
      };
  return (
    <div className='work-industry-container text-center standard-padding-space'>
        <div className='container my-3 text-center py-5 pt-0'>
        <h3 className='body-heading mx-auto mb-4'>Welcome </h3>
        {/* <h3 className='sub-heading mx-auto py-3'>A place to Connect and find clarity</h3> */}
        <p className='body-paragraph mb-0'>I’m thrilled you’ve decided to join me on this journey. Here, we explore the magic of intuitive insights and personal growth together. Whether you're here for a bit of clarity, some healing, or simply to connect, you’re in the right place!</p>
        <p className='body-paragraph mb-0 my-3'>Let’s embark on this path with curiosity and openness. I’m excited to get to know you and support you in discovering the insights and fulfillment you’re seeking.</p>
        <p className='body-paragraph mb-0 mt-3' style={{color:"black",fontSize:"23px"}}>LOOKING FORWARD TO OUR TIME TOGETHER!</p>

        <button className={`genral-btn-light mt-4 mt-xl-5 d-flex align-items-center gap-2 mx-auto `} onClick={()=>{window.open("https://calendly.com/innerfind/")}}>Book An Appointment</button>
        {
          industryList &&

          <div className='industry-list container-fluid px-1 mt-5'>
            <Swiper
            spaceBetween={30}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            navigation={{
              nextEl: '.swiper-button-next-ind',
              prevEl: '.swiper-button-prev-ind',
            }}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
                1400:{
                    slidesPerView:5,
                },
                1800:{
                    slidesPerView:6,
                }
              }}
            pagination={{ clickable: true }}
            observer ={true}
            observeParents={true}
            parallax={true}
            className="ind-list pt-4"
            style={{position:"relative"}}
            id="ind-cards-slider"
            >
                  <div className="swiper-button-prev-ind" onClick={handlePrevButtonClickInd}>
                        <img src="/images/icons/left-arrow-icon.svg" className="arrow-img" alt="arrow icon"/>
                    </div>
              {
                industryData.map((ele,ind)=>{
                  return(<>
                    <SwiperSlide key={ind}>
                      <IndustryCard
                        img={ele.img}
                        title={ele.title}
                      />
                    </SwiperSlide>
                     </>)
                })
              }
                    <div className="swiper-button-next-ind" onClick={handleNextButtonClickInd}>
                        <img src="/images/icons/right-arrow-icon.svg" className="arrow-img" alt="arrow icon"/>
                    </div>
        </Swiper>
        
        </div>
        }
        


        </div>
    </div>
  )
}

export default WorkIndustry