import React,{useState,useEffect} from 'react'
import "./BlogCard.css"
import { Navigate, useNavigate } from 'react-router-dom'
import axios from "axios"
import {Spinner} from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Pagination  } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';

const dummyBlogList = [
    {
        img:"/images/creatives/blog-thumb-1.jpg",
        title:"Discovering Your Inner Self",
        catagory:"News & Articles",
        link:"1"
    },
    {
        img:"/images/creatives/blog-thumb-2.jpg",
        title:"Understanding Intuition",
        catagory:"News & Articles",
        link:"2"
    },
    {
        img:"/images/creatives/blog-thumb-3.jpg",
        title:"Use Your Intuition",
        catagory:"News & Articles",
        link:"3"
    },
    {
        img:"/images/creatives/blog-thumb-1.jpg",
        title:"Discovering Your Inner Self",
        catagory:"News & Articles",
        link:"1"
    },
    {
        img:"/images/creatives/blog-thumb-2.jpg",
        title:"Understanding Intuition",
        catagory:"News & Articles",
        link:"2"
    },
    {
        img:"/images/creatives/blog-thumb-3.jpg",
        title:"Use Your Intuition",
        catagory:"News & Articles",
        link:"3"
    },
    
    
]



function SecondaryCard({
    img,
    date,
    title,
    link,
    catagory,
    redirectionFuntion
})
{
    return(
            <div className=' secondary-img-container ' style={{backgroundImage:`url(${img})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center"}}
              onClick={()=>{redirectionFuntion(link)}}
            >    
             <div style={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 2,
  }}></div>
  <img src={img} alt="thumbnail" className='d-lg-none d-block ' style={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    // height: '100%',
    zIndex: 1,
  }} />
                <div className='d-flex flex-column align-items-start justify-content-end px-3 h-100 pb-3' style={{position:"relative",zIndex:3}}>
                    <p className='body-paragaph  mb-2' style={{color:"white"}}>{catagory}</p>
                    <h3 className='title mb-1  font-mons' style={{color:"white"}}>{title}</h3>
                </div>
            </div>

    )
}

function BlogCard() {
    const navigate = useNavigate()
    const [mainBlogsData, setMainBlogsData] = useState();
    const [blogsData, setBlogsData] = useState();
  
    const [loading, setLoading] = useState(false);
  
    let blogAPICalledId = false;
    let allBlogsCalled = false;
  
    const getAllBlogs = async () => {
      if (allBlogsCalled) return;
      allBlogsCalled = true;
  
      setLoading(true);
      
      axios
        .get(`/blogs`, {})
        .then((res) => {
          if (res.data.status === "success") {
            let Updated_recent_blogs = [];
            
            let mainBlog = res.data.data.slice(0,1);
            setMainBlogsData(mainBlog[0])


            let blog = res.data.data.slice(1, 4);
            setBlogsData(blog);
  
            
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setBlogsData(null)
          setLoading(false);
        });
    };
  
    useEffect(() => {
      // getAllBlogs();
    }, []);

    const convertToSlug = (str) => {
        return str.toLowerCase().replace(/\s+/g, "-");
      };
    
      const redirectUserToBlog = (id) => {
        if (!id) return;
        let slug = convertToSlug(id);
    
        navigate(`/artical/${slug}`);
      };

      const handlePrevButtonClickInd = () => {
        const swiperInstance = document.querySelector('#blog-cards-slider').swiper;
        swiperInstance.slidePrev();
      };
      
      const handleNextButtonClickInd = () => {
        const swiperInstance = document.querySelector('#blog-cards-slider').swiper;
        swiperInstance.slideNext();
      };

  return (
    <div className="BlogCard-container py-5 ">
      <div className=" my-4 my-xxl-5">
        <div className="main">
          <div className="text-content text-center text-lg-start container">
            <div className="row gy-3 align-items-center">
              <div className="col-lg-6">
                <h3 className="body-heading">
                  BLOGS & ARTICLES
                </h3>
              </div>

              <div className="col-lg-6 text-center text-lg-end my-3 my-lg-0">
                <button
                  className="genral-btn-light "
                  onClick={() => {
                    navigate("/blog");
                  }}
                >
                  VIEW ALL
                </button>
              </div>
            </div>
            {/* <p className="body-paragraph font-manrope mt-2 mb-xl-5 mb-3 main-para mx-auto mx-lg-0">
              Ultrices tincidunt faucibus facilisis ullamcorper purus neque
              nulla
            </p> */}
          </div>

          {loading ? (
            <div
              style={{ width: "100%", height: "100vh" }}
              className="d-flex justify-content-center align-items-center"
            >
              <Spinner
                style={{ color: "black", width: "120px", height: "120px" }}
              />
            </div>
          ) : (
            <div className="mt-2 container">
              <Swiper
                spaceBetween={50}
                navigation={{
                  nextEl: ".swiper-button-next-blog",
                  prevEl: ".swiper-button-prev-blog",
                }}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                pagination={{ clickable: true }}
                observer={true}
                observeParents={true}
                parallax={true}
                breakpoints={{
                  280: {
                    slidesPerView: 1,
                  },
                  500: {
                    slidesPerView: 2,
                  },
                  992:{
                    slidesPerView: 3,
                  }
                }}
                className="blog-list pt-5 mt-2"
                style={{ position: "relative",paddingBottom:"100px" }}
                id="blog-cards-slider"
              >
                <div
                  className="swiper-button-prev-blog"
                  onClick={handlePrevButtonClickInd}
                >
                  <img
                    src="/images/icons/left-arrow-icon.svg"
                    className="arrow-img"
                    alt="arrow icon"
                  />
                </div>
                <div className=''>
                {
                    dummyBlogList.map((blog)=>{
                        return(
                            <SwiperSlide>
                            <SecondaryCard
                                img={blog.img}
                                date={""}
                                title={blog.title}
                                catagory={blog.catagory}
                                link={blog.link}
                                redirectionFuntion={redirectUserToBlog}
                            />
                            </SwiperSlide>
                        )
                    })
                }
                </div>
                <div
                  className="swiper-button-next-blog"
                  onClick={handleNextButtonClickInd}
                >
                  <img
                    src="/images/icons/right-arrow-icon.svg"
                    className="arrow-img"
                    alt="arrow icon"
                  />
                </div>
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BlogCard