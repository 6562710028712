import React from 'react'
import NewsList from "../component/NewsList/NewsList"
import HeroBannerDark from '../component/Common Banner Component Dark/HeroBannerDark'
import Footer from '../component/Footer/Footer'
import HeroBanner from '../component/Common Banner Component/HeroBanner'

function News() {
  return (
    <>
    <HeroBannerDark 
      subHeading=""
      Heading="Blogs & Articles"
      nextLineSpanHeading=""
      Paragraph=""
      bgImg="/images/creatives/blog-hero-bg.jpg"
      // headingBgImg="/images/icons/scratch-hero-bg.svg"
      btn={false}
      sideLinks={false}
      bgVideo={false}
      // bgColor="#B1B6AC"
      paddingBottom={false}
    />
    <NewsList/>

    <Footer/>
  
    </>
  )
}

export default News