import React from 'react'
import DropDownBox from "./DropDownBox"
import "./FaqSection.css"

function FaqSection({
    heading = "",
    para = "",
    subHeading = "",
    showImg = false,
    faqData = []
}) {

    const half = Math.ceil(faqData.length / 2); 
    const firstHalf = faqData.slice(0, half); 
    const secondHalf = faqData.slice(half);
    return (
        <div className='faq-container standard-padding-space'>
            <div className='container my-0 my-md-3'>
                <div className=''>
                    <div className='text-content text-center'>
                        <img src='/images/creatives/male-and-female.png' alt='icon' className='img-fluid icon-image' />
                        <h3 className='body-heading my-3'>{heading}</h3>
                        <h6 className='sub-heading my-4' >{subHeading}</h6>
                        <p className='body-paragraph faq-main-content mx-auto mb-5'>{para}</p>
                    </div>

                </div>

                <div className='d-flex flex-lg-row flex-column gap-lg-3 gap-0'>
                    {/* First dropdown container */}
                    <div className='dropdown-container d-flex flex-column '>
                    {firstHalf.map((ele, ind) => (
                        <div key={ind} className=''>
                        <DropDownBox title={ele.title} content={ele.content} />
                        <div className='line-seprator'></div>
                        </div>
                    ))}
                    </div>

                    {/* Second dropdown container */}
                    <div className='dropdown-container d-flex flex-column '>
                    {secondHalf.map((ele, ind) => (
                        <div key={ind} className=''>
                        <DropDownBox title={ele.title} content={ele.content} />
                        <div className='line-seprator'></div>
                        </div>
                    ))}
                    </div>
                </div>


                {/* <div className='dropdown-container  mt-4'>
                    {
                        faqData.map((ele, ind) => {
                            return (
                                <div className='w-50'>
                                    <DropDownBox title={ele.title} content={ele.content} />
                                    <div className='line-seprator'></div>
                                </div>
                            )
                        })
                    }
                </div> */}
            </div>

        </div>
    )
}

export default FaqSection