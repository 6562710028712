import React,{useEffect,useState} from "react";
import "./HeroBannerDark.css"
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";

const HeroBannerDark = ({
  Heading,
  subHeading,
  spanHeading="",
  Paragraph,
  btnText,
  bgVideo=false,
  btn=false,
  btnRef="/",
  socialLinks=false,
  serviceForm=false,
  bgEffect=false,
  heightAuto=false,
  smallHeading=false,
  sideLinks=true,
  bottomSection=true,
  paddingBottom=true,
  prePara=""
}) => {
  const navigate = useNavigate()
  const paragraphs = Paragraph.split('\n').map((paragraph, index) => <p key={index} className='hero-paragraph hero-para color-dark'>{paragraph}</p>);
  return (
    <>
    <div
      className={`common_hero_section_dark  ${heightAuto && "h-cus-auto"}`}
      style={{ background:"transparent" ,paddingBottom:`${paddingBottom ? "300px":"10px"}`}}
        
    >
      <div className={`${bgEffect && "bg-effect"} h-100`}>
       {/* { bgVideo && <video autoPlay loop muted>
        <source src="./images/backgroundVideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>} */}

      <div className="hero-container h-100  px-0"
      
      >
     <div className="wrapper d-flex flex-column pt-xl-0 pt-0">

      <Header
        darkBg={false}
      />
      <div className="container">

      <div 
        className=" d-flex align-items-center justify-content-center py-5 my-auto "
        >

        <div className="d-flex flex-column flex-md-row gap-3 gy-2 w-100 align-items-center mt-5 justify-content-center">
          <div
            className={
              serviceForm
                ? "col-md-6 "
                : "col-12 hero_content_wrapper px-3 w-100"
            }
          >
            <div className="hero-text-content w-100 text-center">
            {subHeading && <div className="d-flex align-items-center gap-3 justify-content-center">
              <div style={{borderBottom:'1px solid black',width:"15%",height:"0"}}></div>
              <h5 className="sub-heading color-dark mb-0">{subHeading}</h5>
              <div style={{borderBottom:'1px solid black',width:"15%",height:"0"}}></div>
            </div>}
            <img src="/images/icons/circular-logo.png" alt="logo" className="img-fluid circular-logo"/>
            { prePara && <p className="hero-paragraph my-3 mx-auto" style={{color:"black"}}>{prePara}</p>}
            <h1 className={`hero-section-heading my-4 px-2 color-dark ${smallHeading && "hero-section-heading-small"}`}
              // style={{color:"#1E1E1E",fontSize: "30px",fontWeight: 400}}
            >
              {Heading}
              <span style={{textTransform:"none"}}>{spanHeading}</span>
            </h1>
            <p className="hero-paragraph-sec px-2 color-dark mx-auto" >{paragraphs}</p>
            {btn && <button className="genral-btn mt-4" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
           
          </div>
          </div>
         
        </div>
        
      </div>
      </div>
      </div>
      </div>
      
      </div>
    </div>
    
    </>
  );
};

export default HeroBannerDark; 