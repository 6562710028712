import React from 'react'
import "./TextContent.css"
import { useNavigate } from 'react-router-dom';
import DropDownContainer from './DropDownContainer';

function TextContent({
  heading,
  preHeadingColorChange="",
  headingColorChange="",
  nextLineHeading="",
  postStylishText="",
  fullWidthContent=false,
  subHeading="",
  subHeadingLine=true,
  postSubHeading="",
  postHeading="",
  postContent="",
  content,
  btn=false,
  btnRefTel=false,
  btnText,
  btnRef="/",
  shiftRow=false,
  img,
  reviewContent= false,
  review,
  reviewUser,
  userImg,
  mobileTextCenter=true,
  btnImg=false,
  dropDown=false,
  dropDownList=[],
  bulletList=false,
  bulletListData=[],
  bulletListBulletIcon="/images/icons/checklist-icon.svg",
  customList=false,
  customListData=[],
  btnEnd=false,
  fontDark=true,
  scratchBefore=false,
  headingBgScratch=false,
  postSignature="",
  id="",
  smallHeading=false,
  imgPadding=false,
  lessSpacedHeading=false
}) {
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className={`body-paragraph my-1 mb-3 ${fontDark ? "color-dark":"color-light"}`}>{paragraph}</p>);
  const navigate = useNavigate()
  return (
    <div 
      className='text-content-section '
      style={{background:`${fontDark ? "transparent":"black"}`}}
      id={id}
    >

  
      <div className={`${!fontDark && "right-gradient-bottom "} `}>
      <div className={`${!fontDark && "left-gradient-upper "} py-4 `}>
      <div className={`container my-4 my-xl-5 ${!fontDark && "py-xl-5 py-3"} `}>
        <div className={`row d-flex ${shiftRow && "flex-row-reverse"} align-items-center gy-2`} >
          <div className={`col-lg-6 p-2 px-3 px-lg-2 d-flex align-items-center justify-content-center ${shiftRow ? "justify-content-lg-end" : "justify-content-lg-start"}`}>
            <img src={img} className={`img-fluid ${imgPadding ? "imgPadding":""} `} alt='content'/>
          </div>
          <div className={`col-lg-6 `}>
            <div className={`text-content ${mobileTextCenter && "text-center text-lg-start"} px-3`}>
              {subHeading && <p className={`sub-heading mb-0 justify-content-lg-start justify-content-center d-flex aling-items-center gap-lg-4 gap-3 ${fontDark ? "color-dark":"color-light"}`}>{subHeadingLine &&<div style={{height:"0",width:"50px",borderTop:`1px solid ${fontDark ? "#252525":"white"}`,margin:"auto 0" }}></div>}{subHeading}</p>}
              {/* <div className='paddLeft'> */}
              <div className={`paddLef d-lg-flex d-block align-items-start ps-0 pe-0 ${shiftRow ? `pe-lg-${scratchBefore ? "0":"5"}` : `ps-lg-${scratchBefore ? "0":"5"}`} `}>
              {/* {
                    scratchBefore && 
                    <img src='/images/icons/heading-before-scratch.svg'  alt='scratch icon' className='pt-4'/>
                  } */}
              <div className="" style={{paddingLeft:`${scratchBefore ? "10px":"0px"}`}}>
                <h3  className={`body-heading py-3 ${fontDark ? "color-dark":"color-light"} ${headingBgScratch && "heading-bg"} ${lessSpacedHeading && "less-spaced-heading"}`} style={smallHeading ? {fontSize:"35px"}:{}}>
                {
                    scratchBefore && 
                    <img src='/images/icons/heading-before-scratch.svg'  alt='scratch icon' className='pt-3 me-4'/>
                  }
                  {preHeadingColorChange && <span className='color-blue'>{preHeadingColorChange}</span>}{heading}{headingColorChange && <span className='color-blue'>{headingColorChange}</span>}<br></br>{nextLineHeading && <span className='color-blue '>{nextLineHeading}</span>}</h3>
                
                {/* <p className={`sub-heading mb-3 mb-xxl-4 ${fontDark ? "color-dark":"color-light"}`}>{postSubHeading}</p> */}
                {postSubHeading &&<p className='body-paragraph mb-3 mt-3' style={{color:"#B1B6AC",fontSize:"23px"}}>{postSubHeading}</p>}
                {
                  postStylishText &&
                  <h3 className='my-3 font-Aervish stylish-font-text' style={{color:"#E0C7C0",fontSize:"48px"}}>{postStylishText}</h3>
                }
                <div className={`${fontDark ? "color-dark":"color-light"} ${!fullWidthContent && "text-content-para"} `} >{paragraphs}</div>
                <h3 className={`body-heading mt-3 my-xl-3 mt-xl-4 ${fontDark ? "color-dark":"color-light"}`}>{postHeading}</h3>
                <p className={`body-paragraph my-1  pb-2`} style={{color:"#4d4f4b",fontSize:"19px",fontWeight:"900"}}><i>{postContent}</i></p>
                

                {
                  btnRefTel ?
                  (
                    <a href='https://calendly.com/innerfind/' style={{textDecoration:"none"}} target='blank'>
                      {btn && <button className={`${fontDark ? "genral-btn-light":"genral-btn"} mt-4 mt-xl-4  d-flex align-items-center gap-2 mx-auto mx-lg-0`}>{btnText}</button>}
                    </a>
                  ):
                  (
                    <>
                    {btn && <button className={`${fontDark ? "genral-btn-light":"genral-btn"} mt-4 mt-xl-4  d-flex align-items-center gap-2 mx-auto mx-lg-0`} onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                    </>
                  )
                }
                
                   {
                reviewContent &&
                <>
                  <div className='line-seprator my-2 mt-4'></div>
                  <div className='d-flex align-items-start gap-3 pt-4'>
                    <img src={userImg} alt='user'/>
                    <div className='my-3'>
                      <p className='body-paragraph mb-1'>{review}</p>
                      <p className='body-paragraph mb-0'>{reviewUser}</p>
                    </div>
                  </div>
                  <button className='text-btn mt-3' onClick={()=>{navigate("/about")}}>ABOUT US</button>
                </>
              }
              {
                dropDown &&
                <>
                  {
                    dropDownList.map((ele,ind)=>{
                      return(
                        <>
                        <DropDownContainer
                          title={ele.title}
                          content={ele.content}
                        />
                        {ind < dropDownList.length-1 ? <div className='line-seprator'></div>:<></>}
                        </>
                      )
                    })
                  }
                </>
              }
              {
                bulletList &&
                <ul className='bulletList-container'>
                  {
                    bulletListData.map((ele,ind)=>{
                      return(
                        <div className='d-flex align-items-start  gap-4 mb-4'>
                          <img src={`${bulletListBulletIcon}`} alt='check box' className='mt-2' />
                          <p className='body-paragraph  text-start'>{ele}</p>
                        </div>
                      )
                    })
                  }
                </ul>
              }
              {
                customList &&
                <ul className='bulletList-container'>
                  {
                    customListData.map((ele,ind)=>{
                      return(
                          <li ><div dangerouslySetInnerHTML={{__html : ele}}></div></li>
                      )
                    })
                  }
                </ul>
              }
              {
                btnEnd &&
                (
                  <a href='tel:905-841-9879' style={{textDecoration:"none"}}>
                    <button className={`${fontDark ? "genral-btn-light":"genral-btn"} mt-4 d-flex align-items-center gap-2 mx-auto mx-lg-0`}>{btnText}</button>
                  </a>
                )

              }
              {
                  postSignature &&
                  <h3 className='my-3 mt-4 font-Aervish stylish-font-text text-end' style={{color:"#E0C7C0",fontSize:"48px"}}>{postSignature}</h3>
                }
              </div>
              </div>
           
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>

      
    </div>
  )
}

export default TextContent