import React from 'react'
import "./CenteredText.css"

function CenteredText({
    title="",
    colorTitle="",
    content="",
    postContent="",
    scratchImg=false,
    lessSpacedHeading=false
}) {
  return (
    <div className='CenteredText-container container standard-padding-space'>
        <div className='text-content text-center'>
            {scratchImg &&
                <img src="/images/icons/scratch-white.svg" alt='scratch icon' className='mb-4'/>
            }
            <h3 className={`body-heading ${lessSpacedHeading && "less-spaced-heading"}`}>
                {title}
            </h3>
            <p className='body-paragraph mt-5' style={{color:"white"}}>{content}</p>
            {/* <br></br> */}
            {postContent && <p className='body-paragraph' style={{color:"white"}}>{postContent}</p>}
        </div>

        <div className='post-content text-center mt-5 pb-4'>

            <h3 className='my-5 font-Aervish stylish-font-text' style={{color:"white"}}>Doing a Reading is For You If…</h3>

            <div className='container checkBox-list d-lg-block d-none mt-4'>
                <div className='row'>
                    <div className='col-lg-4 px-0'>
                        <div className='d-flex flex-column align-items-center'>
                            <div className='d-flex align-items-center w-100'>
                                <img src='/images/icons/white-checkmark-icon.png' alt='check box' className='px-2'/>
                                <div style={
                                    {
                                        width:"100%",
                                        height:"0",
                                        border:"1px dashed white",
                                        borderWidth:"1px",
                                    }
                                }></div>
                            </div>
                            <p className='body-paragraph text-center card-text-left card-text' style={{color:"white"}}>
                            You’re looking for clarity and direction
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-4 px-0'>
                        <div className='d-flex flex-column align-items-center'>
                            <div className='d-flex align-items-center w-100'>
                                <div style={
                                    {
                                        width:"100%",
                                        height:"0",
                                        border:"1px dashed white",
                                        borderWidth:"1px",
                                    }
                                }></div>
                                <img src='/images/icons/white-checkmark-icon.png' alt='check box' className='px-2'/>
                                <div style={
                                    {
                                        width:"100%",
                                        height:"0",
                                        border:"1px dashed white",
                                        borderWidth:"1px",
                                    }
                                }></div>
                            </div>
                            <p className='body-paragraph text-center card-text' style={{color:"white"}}>
                            You’re wanting to open up to more peace in your life
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-4 px-0'>
                        <div className='d-flex flex-column align-items-center '>
                            <div className='d-flex align-items-center w-100'>
                                <div style={
                                    {
                                        width:"100%",
                                        height:"0",
                                        border:"1px dashed white",
                                        borderWidth:"1px",
                                    }
                                }></div>
                                <img src='/images/icons/white-checkmark-icon.png' alt='check box' className='px-2' />
                            </div>
                            <p className='body-paragraph text-center card-text-right card-text' style={{color:"white"}}>
                            You feel ready to receive answers
                            </p>
                        </div>
                    </div>

                </div>

            </div>
            <div className='container checkBox-list-mb d-lg-none d-block mt-4'>
                <div className='row'>
                    <div className='col-12 px-0' >
                        <div className='d-flex flex-column align-items-center gap-2 '>
                            <div className=''>
                                <img src='/images/icons/white-checkmark-icon.png' alt='check box' className='px-2'/>
                            </div>
                            <p className='body-paragraph text-center card-text mb-2' style={{color:"white"}}>
                            You’re looking for clarity and direction
                            </p>
                            <div style={
                                    {
                                        width:"0",
                                        height:"60px",
                                        border:"1px dashed white",
                                        borderWidth:"1px",
                                    }
                                }></div>
                        </div>
                    </div>
                    <div className='col-12 px-0' >
                        <div className='d-flex flex-column align-items-center gap-2 '>
                        <div style={
                                    {
                                        width:"0",
                                        height:"30px",
                                        border:"1px dashed white",
                                        borderWidth:"1px",
                                    }
                                }></div>
                            <div className='mt-2'>
                                <img src='/images/icons/white-checkmark-icon.png' alt='check box' className='px-2'/>
                            </div>
                            <p className='body-paragraph text-center card-text mb-2' style={{color:"white"}}>
                            You’re wanting to open up to more peace in your life
                            </p>
                            <div style={
                                    {
                                        width:"0",
                                        height:"30px",
                                        border:"1px dashed white",
                                        borderWidth:"1px",
                                    }
                                }></div>
                        </div>
                    </div>
                    <div className='col-12 px-0' >
                        <div className='d-flex flex-column align-items-center gap-2 '>
                        <div style={
                                    {
                                        width:"0",
                                        height:"60px",
                                        border:"1px dashed white",
                                        borderWidth:"1px",
                                    }
                                }></div>
                            <div className='mt-2'>
                                <img src='/images/icons/white-checkmark-icon.png' alt='check box' className='px-2'/>
                            </div>
                            <p className='body-paragraph text-center card-text mb-2' style={{color:"white"}} >
                            You feel ready to receive answers
                            </p>
                            
                        </div>
                    </div>
                 

                </div>

            </div>
        </div>
    </div>
  )
}

export default CenteredText