import React from 'react'
import "./CourseSection.css"

function CourseSection() {
  return (
    <div className='CourseSection-container '>
        <img src='/images/creatives/course-section-bg.jpg' alt='bg' className='section-bg'/>
        <div className='text-content text-center px-4 px-lg-1'>
        {/* <h3 className='my-1 font-Aervish ' style={{color:"#4C5957",fontSize:"40px",letterSpacing:"1.5px"}}>INTRODUCING</h3> */}
        <h3 className='mb-1 mt-0 font-Aervish ' style={{color:"#4C5957",fontSize:"76px",letterSpacing:""}}>Introducing</h3>
            <h3 className='body-heading mb-4'>Neuroplasticity & Intuition: Regulating Your Nervous System</h3>
            <p className='body-paragraph'>A self-guided course to help you tune into your intuition and support your nervous system with practical, empowering techniques.</p>
            <button className='genral-btn-light mt-3'>Coming Soon</button>

        </div>
    </div>
  )
}

export default CourseSection