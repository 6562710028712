import React,{useState} from 'react'
import axios from "axios"
import "./ContactPageForm.css"
import {Spinner } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';

function ContactPageForm() {
    const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form-main", {
        email: email,
        name:name,
        message:message,
        phoneNum:phone,
        formType: "Message genrated through Contact form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message sent Successfully!");
          setLoading(false);
          setEmail("");
          // window.open("/thank-you", '_blank');
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send message, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 25;


    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };

  return (
    <div className='ContactPageForm-container standard-padding-space pt-0 pb-lg-4'>
        <div className='container my-4 mt-0'>
        <div className='row px-2 px-md-5 gy-4 align-items-center'>
                <div className='col-12 text-center '>
                    <h3 className='mb-5 font-Aervish stylish-font-text' style={{color:"#E0C7C0"}}>Connect With Me</h3>
                    {/* <h3 className='body-heading ' ></h3> */}
                </div>
               
                <div className='col-12 mt-xl-5 mt-4 contact-inp-form'>
                    <form className='form d-flex flex-column gap-3' onSubmit={formSubmitHandler}>
                    
                      <div className='d-flex flex-lg-row flex-column align-items-center w-100 gap-3 gap-lg-4'>
                      <input 
                         type="text" 
                         value={name}
                         required
                         maxLength="36"
                         onChange={handleNameChange}
                         placeholder="Your Full Name"  
                        className=' input-field w-100' 
                        />
                        <input 
                        type="email" 
                        value={email}
                        required
                        maxLength="66"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your Email Address" 
                        className=' input-field w-100' />
                    
                      </div>
                        
                        <input 
                         type="tel" 
                         value={phone}
                         maxLength="25"
                         required
                         onChange={handlePhoneChange}
                        placeholder="Phone Number" 
                        className='input-field' />
                        
                        <textarea 
                        className="input-field" 
                        value={message}
                        maxLength="350"
                        required
                        onChange={(e) => setMessage(e.target.value)}
                        rows={4} placeholder="Write Your Message"
                        ></textarea>
                        <button className="genral-btn-light service-from-btn  mt-2" type='submit'>Submit
                        {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                                    />
                                ) : (
                                success ? (
                                    <div>
                                    {setLoading(false)}
                                    {alert(success)}
                                    {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                    <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                    </div>
                                    )
                                )
                                )}
                  
                        </button>
                    </form>
                </div>
            </div>  
        </div>
    </div>
  )
}

export default ContactPageForm