import './App.css';
import "./bootstrap.min.css"
import Home from './screens/Home';
import { Routes, Route, Navigate } from 'react-router-dom';

import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";

import NotFound from "./screens/NotFound"


import LocationScreen from './screens/LocationScreens/LocationScreen';
import LocationPages from "./component/LocationPages_/LocationPages"
import About from './screens/About';
import Service from './screens/Service';
import Pricing from './screens/Pricing';
import News from './screens/News';
import Testimonial from './screens/Testimonial';
import Contact from './screens/Contact';
import BlogInner from './screens/BlogInner';
import PrivacyPolicy from './screens/PrivacyPolicy';
import CookiePolicy from './screens/CookiePolicy';
import TermsOfService from './screens/TermsOfService';


function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" replace={true}/>} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/blog" element={<News />} /> 
        <Route path="/artical/:id" element={<BlogInner />} /> 
        <Route path="/testimonials" element={<Testimonial />} />
        <Route path="/contact" element={<Contact />} />
        
        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />

        

        
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/term-of-service" element={<TermsOfService />} />

        <Route path="*" element={<NotFound />} />

        
        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>
      </Routes>
    </div>
  );
}

export default App;


