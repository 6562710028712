import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import { Helmet } from 'react-helmet'
import Footer from '../component/Footer/Footer'
import ContactPageForm from '../component/ContactPageForm/ContactPageForm'
import ContactPageContactDetails from '../component/ContactPageContactDetails/ContactPageContactDetails'
import SocialMediaFeed from '../component/SocialMediaFeed/SocialMediaFeed'
import { InlineWidget } from "react-calendly";

function Contact() {
  return (
    <>
        <Helmet>
          <title> 
          
          </title>
          <meta 
          name='description' 
          content="" 
          />
          
        </Helmet>
        
      
      <HeroBanner
      subHeading=""
      Heading="Connect With Me"
      nextLineSpanHeading=""
      Paragraph=""
      bgImg="/images/creatives/contact-hero-bg.jpg"
      // headingBgImg="/images/icons/scratch-hero-bg.svg"
      btn={false}
      sideLinks={false}
      bgVideo={false}
      smallHeading={true}
      // mobileBgContain={true}
      // bgColor="#B1B6AC"
      bgImgLg={"/images/creatives/contact-hero-bg-mob.jpg"}
      // autoMinHeight={true}
      />

      <ContactPageContactDetails/>


      <ContactPageForm/>
      {/* <InlineWidget url="https://calendly.com/innerfind" styles={{height:"700px",margin:"0"}}/> */}

    <Footer/>
    </>
  )
}

export default Contact