import React from 'react'
import "./ContactPageContactDetails.css"

function ContactPageContactDetails() {
  return (
    <div className='ContactPageContactDetails-container p-1'>
        <div className='container'>
        <div className='card-section row'>
                <div className='card-box col-lg-4 d-flex flex-column align-items-center  px-5 py-5 pt-0 '>
                    <img src='/images/icons/pen-icon.svg' alt='icon' className='my-5'/>
                    <h3 className='body-heading mb-3' style={{color:"#7F837A",fontSize:"28px"}}>Get in touch</h3>
                    <p className='body-paragraph text-center mt-3' style={{color:"#7F837A"}}>Fill out the contact form and I will reach you within 48 hours</p>
                </div>
                <div className='card-box col-lg-4 d-flex flex-column align-items-center px-5 py-5 pt-0'>
                    <img src='/images/icons/insta-contact-icon.svg' alt='icon' className='my-5'/>
                    <h3 className='body-heading mb-3' style={{color:"#7F837A",fontSize:"28px"}}>Visit Me</h3>
                    <p className='body-paragraph text-center mt-3' style={{color:"#7F837A"}}>
                        Instagram: <a className='body-paragraph details-anchor' style={{textDecoration:"none",cursor:"pointer"}} href='https://www.instagram.com/cupreadingswc/' target='blank'>cupreadingswc</a>
                        <br></br>
                        Facebook: <a className='body-paragraph details-anchor' style={{textDecoration:"none",cursor:"pointer"}} href='https://www.facebook.com/cupreadingswc/' target='blank'>cupreadingswc</a>
                         </p>
                </div>
                <div className='card-box col-lg-4 d-flex flex-column align-items-center px-5 py-5 pt-0'>
                    <img src='/images/icons/sms-contact-icon.svg' alt='icon' className='my-5'/>
                    <h3 className='body-heading mb-3' style={{color:"#7F837A",fontSize:"28px"}}>Contact Me</h3>
                    <p className='body-paragraph text-center mt-3' style={{color:"#7F837A"}}>
                        <a className='body-paragraph details-anchor' style={{textDecoration:"none",cursor:"pointer"}} href='mailto:Info@cupreadingswc.com'>Info@cupreadingswc.com</a>
                        <br></br>
                        <a className='body-paragraph details-anchor' style={{textDecoration:"none",cursor:"pointer"}} href='tel:+1 437 328 3990'>+1 437 328 3990</a>
                        </p>
                </div>

            </div>
        </div>
    </div>
  )
}

export default ContactPageContactDetails