import React from 'react'
import "./ContactForm.css"

function ContactForm(
    {
        Heading,
        spanHeading,
        Paragraph,
        btnText,
        btn=false,
        socialLinks=true,

    }
) {
  return (
    <div className='contact-form-container standard-margin-space standard-padding-space'>
        <div className='container-fluid'>
            <div className='row px-2 px-md-5 gy-4'>
                <div className='col-lg-6 text-left'>
                    <div className='left-text-container'>
                        <h1 className="hero-section-heading " style={{fontSize:"44px",fontWeight:"600",color:"#303030",textTransform:"capitalize"}}>
                            {Heading}
                            <span className="heading-color-change" style={{fontSize:"44px",fontWeight:"600",color:"#85339B",textTransform:"capitalize"}}>{spanHeading}</span>
                        </h1>
                        <p className="body-paragraph mb-4 mt-3 w-75">{Paragraph}</p>
                        {btn && <button className="hero-btn mt-4">{btnText}</button>}
                        {socialLinks && (
                        <div className="social-links py-2 d-flex flex-column align-items-start justify-content-start  gap-4">
                            <div className="first-row d-flex flex-column flex-xxl-row gap-4">
                            <div className="link-container d-flex align-items-center gap-1">
                                <img src="/images/icons/social-1.svg" className='social-icons' alt='icon'/>
                                <p className="m-0" style={{fontSize:"16px",fontWeight:"400"}}>(416) 522-5000</p>
                            </div>
                            <div className="link-container d-flex align-items-center gap-1">
                                <img src="/images/icons/social-2.svg" className='social-icons' alt='icon'/>
                                <p className="m-0" style={{fontSize:"16px",fontWeight:"400"}}>info@venuswavetheraphy.com</p>
                            </div>
                            </div>
                            <div className="link-container d-flex align-items-center gap-1">
                            <img src="/images/icons/social-3.svg" className='social-icons' alt='icon'/>
                            <div>
                                <p className="m-0" style={{fontSize:"18px",fontWeight:"500"}}>Truelife Wellness and Physiotherapy Location</p>
                                <p className="m-0" style={{fontSize:"16px",fontWeight:"400",color:" #8f8f8f"}}>2930 Islington Ave #2, North York, ON M9L 2K5</p>
                            </div>
                            </div>
                            <div className="link-container d-flex align-items-center gap-1">
                            <img src="/images/icons/social-3.svg" className='social-icons' alt='icon'/>
                            <div>
                                <p className="m-0" style={{fontSize:"18px",fontWeight:"500"}}>Enhance Spa Location</p>
                                <p className="m-0" style={{fontSize:"16px",fontWeight:"400",color:" #8f8f8f"}}>420 Bronte Street Suite 105B, Milton</p>
                            </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='form d-flex flex-column gap-3'>
                    <div className="first-row d-flex flex-column flex-xl-row gap-xl-4 gap-3">
                        <input type="text" placeholder="Your Full Name" className='w-xl-50 w-100 input-field' />
                        <input type="text" placeholder="Your Email Address" className='w-xl-50 w-100 input-field' />
                    </div>
                        <input type="text" placeholder="Phone Number" className='input-field' />
                        <select 
                            placeholder="Urinary incontinence / Bladder Leakage"
                            className='input-field'
                        >
                            <option value="Urinaryincontinence">Urinary incontinence</option>
                            <option value="BladderLeakage">Bladder Leakage</option>
                        </select>
                        <textarea className="input-field" rows={4} placeholder="Write Message"></textarea>
                        <button className="service-from-btn  mt-2">Submit</button>
                    </div>
                    </div>
                </div>  
            </div>
        </div>  

  )
}

export default ContactForm