import React, { useEffect, useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'
import BlogCard from '../component/BlogCard/BlogCard'
import WorkIndustry from '../component/WorkIndustry/WorkIndustry'
import HomeGreyContainer from '../component/HomeGreyContainer/HomeGreyContainer'
import PlainText from '../component/PlainText/PlainText'
import ContactForm from '../component/ContactForm/ContactForm'
import Contactform from '../component/Contact Form/Contactform'
import CourseSection from '../component/CourseSection/CourseSection'

const servicesData = [
  {
    title: "Services",
    btnText: "LEARN MORE",
    btnRef: "/services",
    para:"Empower your journey by embracing change and new possibilities",
    img:"/images/creatives/page-card-text-1.jpg",
  },
  {
    title: "Pricing",
    btnText: "LEARN MORE",
    btnRef: "/pricing",
    para:"Invest in what feels right for you on your path forward",
    img:"/images/creatives/page-card-text-2.jpg",
  },
  {
    title: "About",
    btnText: "LEARN MORE",
    btnRef: "/about",
    para:"My experiences inspire me to connect openly with others",
    img:"/images/creatives/page-card-text-3.jpg",
  },

]



function Home() {
  const urlParams = new URLSearchParams(window.location.search);
const [specificSection, setSPecificSection] = useState(urlParams.get('id'))
useEffect(() => {
  const specialComponentId = urlParams.get('id');
  if (specialComponentId == "what-to-expect-in-person") {

    const specialComponent = document.getElementById("what-to-expect-in-person");
    if (specialComponent) {
      setSPecificSection(specialComponentId)
      specialComponent.scrollIntoView({ behavior: 'smooth' });
    }
  }
  else if (specialComponentId == "what-to-expect-in-remote") {

    const specialComponent = document.getElementById("what-to-expect-in-remote");
    if (specialComponent) {
      setSPecificSection(specialComponentId)
      specialComponent.scrollIntoView({ behavior: 'smooth' });
    }
  }
  else if (specialComponentId == "one-on-one-intutive") {

    const specialComponent = document.getElementById("one-on-one-intutive");
    if (specialComponent) {
      setSPecificSection(specialComponentId)
      specialComponent.scrollIntoView({ behavior: 'smooth' });
    }
  }
  else if (specialComponentId == "partner-intutive") {

    const specialComponent = document.getElementById("partner-intutive");
    if (specialComponent) {
      setSPecificSection(specialComponentId)
      specialComponent.scrollIntoView({ behavior: 'smooth' });
    }
  }
}, [specificSection]);
  return (
    <div >
      <Helmet>
        <title>

        </title>
        <meta
          name='description'
          content=""
        />

      </Helmet>


      <HeroBanner
        subHeading=""
        Heading="DISCOVERING"
        nextLineSpanHeading=" CHANGE"
        // Heading="CONNECTION "
        // nextLineSpanHeading="& CLARITY"
        Paragraph=""
        bgImg=""
        btn={false}
        sideLinks={false}
        bgVideo={true}
        bgColor=""
        bottomCenteredBox={true}
      />

      <WorkIndustry
        industryList={false}
      />

      <PlainText
        // subHeading={"Our Services"}
        // heading={"Lorem Ipsum is simply dummy text"}
        btn={false}
        // bgImg={"/images/creatives/services-card-section-bg.jpg"}
        pageLinkedSliderCard={servicesData}
        btnRefState={setSPecificSection}
        removePaddingHomeCard={true}
      />

      {/* <TextContent
         subHeading=""
         heading= "What Is Cup Reading?"
         content="Cup reading is an ancient art used to read your energy and give you insight around any questions you may have about your life. This often includes relevant information about your past, present, and future."
         btn={true}
         btnText="Book An Appointment"
         scratchBefore={true}
         btnRef='/'
         shiftRow={false}
         img="/images/creatives/home-text-1.png"
         reviewContent={false}
         btnImg={false}
         fontDark={true}
         /> */}


      <TextContent
        subHeading=""
        heading="Hi, I’m Christine Therese"
        // postSubHeading='HI AND WELCOME!'
        headingColorChange=""
        fullWidthContent={true}
        // content={"My name is Christine Therese, and I am the founder and creator of Inner Findand I’m so glad you’re here.\n Inner Find is a journey that first began in 2016 when I decided to take the plunge, trust my spiritual gifts, and begin offering intuitive cup readings. I am passionate about, and extremely honoured to have the privilege to connect with people all over the world through both in person and remote readings. \n I love to create an honest and open space to connect, and to provide my clients with a sense of restored balance, clarity, and alignment."}
        content={"The founder and creator of Inner Find and I’m so glad you’re here.\n Inner Find began its journey in 2016 when I took the plunge, trusted my spiritual gifts, and started offering intuitive cup readings.\n I am passionate about and deeply honored to connect with people from all over the world through both in-person and remote readings.\n I love creating an honest and open space to connect, and to provide my clients with a sense of restored balance, clarity, and alignment."}
        btn={true}
        btnText="My Story"
        btnRef='/about'
        shiftRow={false}
        img="/images/creatives/home-text-3-new.jpg"
        // headingBgScratch={true}
        reviewContent={false}
        btnImg={true}
        fontDark={true}
        smallHeading={true}
      />

      <HomeGreyContainer />

        <CourseSection/>

      {/* <PlainText
        heading={"Schedule Your Appointment Today"}
        content={"Lorem ipsum dolor sit amet, consectetur adipiscing elit sed faucibus id ultrices nullam viverra commodo sed ultrices tincidunt. viverra commodo sed ultrices tincidunt"}
        btnText={"Book An Appointment"}
        btn={true}
        btnRef="/"
        bgImg={"/images/creatives/plain-text-bg.jpg"}
      /> */}

      <Testimonials />
      <Contactform

      />
      <BlogCard />



      <Footer
        newsletterSection={false}
      />
    </div>
  )
}

export default Home