import React, { useState } from 'react'
import "./SocialMediaFeed.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay  } from "swiper/modules";
import 'swiper/css';
import Modal from '../Model/Modal';

const socialMediaFeedData = [
    "/images/creatives/social-media-feed-1.jpeg",
    "/images/creatives/social-media-feed-2.jpeg",
    "/images/creatives/social-media-feed-3.jpeg",
    "/images/creatives/social-media-feed-4.jpeg",

    "/images/creatives/social-media-feed-5.jpeg",
    "/images/creatives/social-media-feed-6.jpeg",
    "/images/creatives/social-media-feed-7.jpeg",
    "/images/creatives/social-media-feed-8.jpeg",
    "/images/creatives/social-media-feed-10.jpeg",
    "/images/creatives/social-media-feed-11.jpeg",
    "/images/creatives/social-media-feed-12.jpeg",
]

function SocialMediaFeed() {
  const [modalImg , setModalImg]  =useState(false)
  const [modalState , setModalState]  =useState(false)

  const ToogleModal = ()=>{
    setModalState(false)
    setModalImg(false)
  }
  return (
    <div className='px-lg-0 px-4'>
    <div className='SocialMediaFeed-container container  px-lg-0 px-1' >
      {/* <div style={{background:"#B1B6AC" , width:"100%" , height:"28px",transform:"translateY(-28px)"}}></div> */}
        <div className=' text-center py-4 pt-0 px-lg-5 px-4'>
            {/* <h3 className='mb-4 pb-3 font-Aervish stylish-font-text' style={{color:"#E0C7C0"}}>Social Media Feed</h3> */}
            {/* <h3 className="body-heading color-dark mt-4 main-heading mx-auto mb-3 pt-4">TESTIMONIAL</h3> */}
            <div>
            <Swiper
        spaceBetween={20}
        navigation={{
          nextEl: '.swiper-button-next-test',
          prevEl: '.swiper-button-prev-test',
        }}
        loop={true}
        autoplay={
          {
            delay:2000,
            disableOnInteraction:false
          }
        }
        modules={[Autoplay]}
        pagination={{ clickable: true }}
        observer ={true}
        observeParents={true}
        parallax={true} 
        
        breakpoints={{
            280: {
              slidesPerView: 1,
            },
            993: {
              slidesPerView: 4,
            },
          
          }}
          className="testimonials-list py-5 mt-2"
          style={{position:"relative",}}
          id="test-cards-slider"
            >
                {
                    socialMediaFeedData.map((ele)=>{
                        return(
                            <SwiperSlide>
                                <div 
                                    className='feedCard-box'
                                    // style={{backgroundImage:`url("${ele}")`}}
                                    onClick={()=>{
                                      setModalImg(ele)
                                    }}
                                >
                                    <img src={ele} style={{width:'100%',height:"auto"}} alt='testimonail'/>

                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            
            </Swiper>

            </div>

                {
                  modalImg &&
                  <Modal
                    status={modalImg}
                    closeModal={ToogleModal}
                  >
                    <img src={modalImg} alt='img' className='img-fluid' style={{height:"80vh",width:"fit-content"}}/>
                  </Modal>
                }

            {/* <button className='genral-btn-light mt-5'>Explore Now</button> */}
        </div>
    </div>
    </div>
  )
}

export default SocialMediaFeed