import React from 'react'
import { Helmet } from 'react-helmet'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import PlainText from '../component/PlainText/PlainText'
import EGiftCard from '../component/EGiftCard/EGiftCard'
import Footer from '../component/Footer/Footer'

function Pricing() {
  return (
    <>
    <Helmet>
        <title> 
        
        </title>
        <meta 
        name='description' 
        content="" 
        />
    </Helmet>
  

    <HeroBanner 
    subHeading=""
    Heading=""
    nextLineSpanHeading=""
    Paragraph=""
    bgImg="/images/creatives/pricing-hero-bg-new.png"
    btn={false}
    sideLinks={false}
    bgVideo={false}
    bgImgLg={"/images/creatives/pricing-hero-bg-mob-new.png"}
    />


    <PlainText
      heading={"Intuitive Readings Now Available"}
      content={"I’m pleased to offer individual and partnered intuitive readings, all conducted virtually via Zoom or phone call. This allows us to connect comfortably and conveniently from wherever you are."}
      btn={false}
      bgImg={""}
      wideContent={true}
      // headingTextbg={"/images/icons/heading-beside-scratch.svg"}
      GreyPricingCard={true}
      removePaddingPricingCard={true}
    />


    <TextContent
        heading= "Booking Your Session"
        content={"Once you book your session, you’ll receive detailed instructions to prepare for our virtual meeting, ensuring everything is set for a smooth and productive experience.\n I’m here to provide supportive and insightful guidance tailored to your needs. I look forward to connecting with you and exploring the insights that matter most to you."}
        btn={true}
        btnText={"Book An Appointment"}
        btnRefTel={true}
        shiftRow={true}
        img="/images/creatives/pricing-text-1.jpg"
        reviewContent={false}
        btnImg={false}
        fontDark={true}
        // headingBgScratch={true}
        fullWidthContent={true}
        imgPadding={true}
    />

   <EGiftCard/>

   <Footer/>

    </>
  )
}

export default Pricing