import React,{useState} from 'react'

function DropDownBox(props) {
    const [dropOpen , setDropOpen] = useState(false)
  return (
    <div
      className={`dropdown-box container my-0`}
      onClick={() => {
        setDropOpen((prev) => {
          return !prev;
        });
      }}
      
    >
      <div className="box-head d-flex align-items-center justify-content-between gap-3">
        {/* <h5 className="title mb-0" >{props.title}</h5> */}
        <h5 className="body-paragraph mb-0" >{props.title}</h5>
        <div style={{minWidth:"40px"}}>
          <img src={dropOpen ? '/images/icons/dropdown-less-icon.png':'/images/icons/dropdown-less-icon.png'} className='eye-icon' alt='dropdown-icon'/>
        </div>
      </div>
      <div className="box-content my-4" style={dropOpen?{animation:"bottomBoxOpen .6s ease-in-out",display:"block"}:{display:"none"}}>
        <div className='mb-0' dangerouslySetInnerHTML={{__html:props.content}}></div>
      </div>
    </div>
  );
}

export default DropDownBox