import React, { useEffect, useState } from 'react'
import "./PlainText.css"
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';


function PlainText({
  subHeading = "",
  heading = "", 
  codeHeading = "", 
  headingBg = null,
  headingTextbg = "",
  content = "",
  btnText = "",
  btn = false,
  btnRef = "/",
  bgImg = "",
  wideContent = false,
  sliderCard = null,
  GreyPricingCard = false,
  btnRefState = null,
  btnRefTel=false,
  pageLinkedSliderCard=null,
  bulletListData=null,
  centerBullets=false,
  sliderCardBefore=null,
  removePaddingHomeCard=false,
  removePaddingServiceCard=false,
  removePaddingPricingCard=false,
  bgImgLg=null,
  lessSpacedHeading=false
}) {
  const navigate = useNavigate()
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 993);
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className={`body-paragraph my-2 ${wideContent ? "main-para-full" : "main-para"} mx-auto`}>{paragraph}</p>);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 993);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 
  return (
    <div
      className={`PlainText-container standard-padding-space ${removePaddingHomeCard||removePaddingServiceCard ? "pt-0":""} ${removePaddingPricingCard ? "pb-0":""}`}
      style={{ backgroundImage: `url(${isSmallScreen&& bgImgLg ?bgImgLg: bgImg})` }}
    >
      <div
        className={`container text-center ${removePaddingHomeCard||removePaddingPricingCard ? "pb-0":"pb-5 "}`}
        style={
          headingBg ?
            {
              backgroundImage: `url("${headingBg}")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
            }
            :
            {

            }
        }
      >
        {
          subHeading &&
          <h3 className='mb-2 font-Aervish stylish-font-text'>{subHeading}</h3>
        }
        {
          heading &&
          <h3
            className={`body-heading py-2 ${lessSpacedHeading && "less-spaced-heading"}`}
            style={
              headingTextbg ?
                {
                  backgroundImage: `url("${headingTextbg}")`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }
                :
                {

                }
            }
          >{heading}</h3>
        }
        {
          codeHeading && 
          <h3
          className='body-heading py-2'
          style={
            headingTextbg ?
              {
                backgroundImage: `url("${headingTextbg}")`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top center",
              }
              :
              {

              }
          }
          dangerouslySetInnerHTML={{__html:codeHeading}}
        ></h3>
        }
        {paragraphs && <p className='body-paragraph my-3  main-para mx-auto'>{paragraphs}</p>}
        {
          sliderCardBefore &&
          <Swiper
            spaceBetween={50}
            navigation={{
              nextEl: ".swiper-button-next-blog",
              prevEl: ".swiper-button-prev-blog",
            }}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            pagination={{ clickable: true }}
            observer={true}
            observeParents={true}
            parallax={true}
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              769: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 2,
              }
            }}
            className="blog-list mt-0 "
            style={{ position: "relative",zIndex:0}}
            id="blog-cards-slider"
          >
            {
              sliderCardBefore.map((ele) => {
                return (
                  <SwiperSlide>
                    <div style={{backgroundImage:`url(${ele.cardBg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",padding:"40px",paddingTop:"20px"}}>
                    <h3 
                      className='body-heading d-flex align-items-center justify-content-between' 
                      style={{ fontSize: "24px",paddingBottom:"10px",borderBottom:"4px solid #7b8583" }}
                       onClick={() => {

                        if (btnRefState) {
                          btnRefState(ele.btnRef)
                        }
                        
                          navigate(ele.btnRef)
                      }}
                      >
                      {ele.title}
                      <img src='/images/icons/slider-card-btn-arrow.png' className='ms-4' alt="arrow"/>
                    </h3>
                    
                    </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        }
        {btn &&
          <button
            className='genral-btn-light mt-3'
            onClick={() => {
              if(btnRefTel)
                {
                  window.open("https://calendly.com/innerfind/")
                }
                else{
                  navigate(btnRef)
                }
            }}
          >{btnText}</button>
        }
        {
          sliderCard &&
          <Swiper
            spaceBetween={50}
            navigation={{
              nextEl: ".swiper-button-next-blog",
              prevEl: ".swiper-button-prev-blog",
            }}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            pagination={{ clickable: true }}
            observer={true}
            observeParents={true}
            parallax={true}
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              769: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 2,
              }
            }}
            className="blog-list pt-5 mt-2 "
            style={{ position: "relative"}}
            id="blog-cards-slider"
          >
            {
              sliderCard.map((ele) => {
                return (
                  <SwiperSlide>
                    <div style={{backgroundImage:`url(${ele.cardBg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",padding:"40px"}}>
                    <div className='card-box d-flex flex-column justify-content-center ' style={{padding:"100px 50px"}}>
                      <h3 className='body-heading' style={{ fontSize: "24px", padding: "0 5rem",paddingBottom:"10px" }}>{ele.title}</h3>
                      <button
                        className='genral-btn-light mt-4'
                        onClick={() => {

                          if (btnRefState) {
                            btnRefState(ele.btnRef)
                          }
                          navigate(ele.btnRef)
                        }}
                      >{ele.btnText}</button>
                    </div>
                    </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        }
         {
                bulletListData &&
                <ul className={`bulletList-container pt-2 mx-auto  `}>
                  {
                    bulletListData.map((ele,ind)=>{
                      return(
                        <div className={`d-flex align-items-start ${centerBullets ? "justify-content-center" : "justify-content-start"} gap-4 mb-3 `}>
                          <img src={`/images/icons/custom-service-text-bullet.svg`} alt='check box' className='mt-2' />
                          <p className={`body-paragraph ${centerBullets ? "text-center" : "text-start"} mb-0 `}>{ele}</p>
                        </div>
                      )
                    })
                  }
                </ul>
              }
        {
          pageLinkedSliderCard &&
          <div
            className='row gy-5'
          >
            {
              pageLinkedSliderCard.map((ele) => {
                return (
                  <div className='col-lg-4'>
                    <div className='card-box-round d-flex flex-column justify-content-start pt-3 pt-xxl-4 px-4' style={{height:"100%"}}>
                      <img src={ele.img} alt='card img' className='pageChangecard-img'/>
                      <h3 className='body-heading mt-2' style={{ fontWeight:"500",padding:"21px 0 0 0"}}>{ele.title}</h3>
                      <p className='body-paragraph text-center px-4 py-2'>{ele.para}</p>
                      <button
                        className='genral-btn-light mx-auto mt-auto'
                        onClick={() => {

                          if (btnRefState) {
                            btnRefState(ele.btnRef)
                          }
                          navigate(ele.btnRef)
                        }}
                      >{ele.btnText}</button>
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
        {
          GreyPricingCard &&

          <GreyPricingCardBox />
        }
      </div>

    </div>
  )
}


function GreyPricingCardBox({

}) {
  return (
    <div className='GreyPricingCardBox container-fluid '>
      <div className='row gy-4 mt-3'>
        <div className='col-lg-6'>
          <GreyCardBox
            title="One-on-One Intuitive Readings"
            contentData={[
              {
                title: "30 Minutes",
                price: "$110.00",
                calendlyLink:"https://calendly.com/innerfind/30-minute-session"
              },
              {
                title: "45 Minutes",
                price: "$155.00",
                calendlyLink:"https://calendly.com/innerfind/45-minute-session"
              },
              {
                title: "60 Minutes",
                price: "$210.00",
                calendlyLink:"https://calendly.com/innerfind/60-minute-session"
              },
              {
                title: "90 Minutes",
                price: "$280.00",
                calendlyLink:"https://calendly.com/innerfind/90-minute-session"
              },
            ]}
          />
        </div>
        <div className='col-lg-6'>
          <GreyCardBox
            title="Partnered Intuitive Readings"
            contentData={[
              {
                title: "30 Minutes",
                price: "$115.00",
                calendlyLink:"https://calendly.com/innerfind/30-minute-session"
              },
              {
                title: "45 Minutes",
                price: "$165.00",
                calendlyLink:"https://calendly.com/innerfind/45-minute-session"
              },
              {
                title: "60 Minutes",
                price: "$225.00",
                calendlyLink:"https://calendly.com/innerfind/60-minute-session"
              },
              {
                title: "90 Minutes",
                price: "$295.00",
                calendlyLink:"https://calendly.com/innerfind/90-minute-session"
              },
            ]}
          />
        </div>
        <div className='col-lg-12'>
          <GreyCardBox
            title="Text-Based Session"
            contentData={[

              {
                title: "15 Minutes",
                price: "$50.00",
                calendlyLink:"https://calendly.com/innerfind/15-minute-session"
              },

            ]}
          />
        </div>

      </div>
    </div>
  )
}

function GreyCardBox({
  title = "",
  contentData = [],
}) {
  return (
    <div className='w-100'>
      <div className='heading-tab px-3 py-4'>
        <h3 style={{ color: "white" }} className='font-mons'>{title}</h3>
      </div>
      {
        contentData.map((ele) => {
          return (
            <div className='content-tab px-lg-5 px-4 py-4 d-flex align-items-center justify-content-between mb-1'>
              <h4 className='font-mons' style={{ fontWeight: "bold", textDecoration: "underline", color: "#4C5957", cursor: "pointer" }} onClick={()=>{window.open(ele.calendlyLink)}}>{ele.title}</h4>
              <h4 className='font-lato' style={{ fontWeight: "bold", color: "#4C5957" }}>{ele.price}</h4>
            </div>
          )
        })
      }

    </div>
  )
}

export default PlainText