import React from 'react'
import "./LatestNews.css"

function LatestNews({firstBlog,redirectionFunction}) {
  return (
    <div className="news_container standard-margin-space ">
        <div className='container' >
            <div className='row align-items-center'>
                <div className='col-md-6'>
                    <div className="news_img_container">
                        <img className="latest_news_img img-fluid" src={firstBlog.blog_image} alt='img-fluid'/>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="text_content">
                        <h3 className='h3_main mb-2'><span className='sub-heading color-dark'>MOST POPULAR BLOG</span></h3>
                        <h2 className='h1_main my-3 body-heading'>{firstBlog.title}</h2>
                        
                        <p className='para_main mb-3 body-paragraph'><div dangerouslySetInnerHTML={{__html:firstBlog.brief_paragraph}}></div></p>
                        <div className='line-seprator'></div>
                        <div className="blog_news_logo">
                        <div className="d-flex justify-content-between w-100">
                            <div className='d-flex align-items-center gap-2'>
                                <p className='body-paragraph color-dark mb-0'>{firstBlog.catagory}</p>
                                <div style={{borderBottom:"1px solid black ",width:"20px"}}></div>
                                <p className='body-paragraph color-dark mb-0'>7 MIN</p>
                            </div>
                            <p 
                                className='body-paragraph color-dark anchor-text mb-0'
                                onClick={()=>{redirectionFunction(firstBlog.slug_url)}}
                            >Read More</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LatestNews