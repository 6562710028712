import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'

function PrivacyPolicy() {
  return (
    <div>
      <HeroBanner
        subHeading=""
        Heading="Privacy Policy"
        nextLineSpanHeading=""
        Paragraph=""
        bgImg="/images/creatives/plane-hero-bg-light.png"
        btn={false}
        sideLinks={false}
        bgColor=""
      />

      <div className="container privacy-policy-container mt-5">
        <div class="elementor-widget-container">
          <p className='body-paragraph'>
            This web page represents a legal document and is the Privacy Policy
            for Inner Find Corporation and all its associated Products and
            Services. By using our Services, you agree to fully comply with and
            be bound by the following policy each time you use our Services.
            Please review the following terms carefully.&nbsp;&nbsp;&nbsp;
          </p>
          <p className='body-paragraph'>
            This Privacy Notice describes the types of information we collect or
            receive and how we may use that information. The information we
            collect includes personal information and non-personal information,
            and such terms are defined below.
          </p>
          <p className='body-paragraph'>
            The Privacy Notice also describes the measures we take to protect
            the security of personal information. We also tell you how you can
            reach us to ask us to (i) access, change or not use the personal
            information you have provided to us, (ii) withdraw any consent you
            previously provided to us, and (iii) answer any questions you may
            have about our privacy practices.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Your Consent</strong>
          </p>
          <p className='body-paragraph'>
            By using our Services, you consent to the collection and use of this
            information by us as set forth in this Privacy Statement and amended
            from time to time. If we decide to change our privacy practices, we
            will post those changes on this page so that you are always kept
            informed of what data we collect, how we use it, and under what
            circumstances we disclose it. Please check this page for changes
            from time to time to make sure you are aware of our latest privacy
            practices.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Your Privacy</strong>
          </p>
          <p className='body-paragraph'>
            We are committed to respecting your privacy. We recognize that when
            you choose to provide us with information about yourself, you trust
            us to act responsibly. That’s why we have put a policy in place to
            protect your personal information. You may always use our Services
            without giving us any personal information. Please read on for more
            details about our privacy practices.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Our Collection and Use of Personal InfoRMation</strong>
          </p>
          <p className='body-paragraph'>
            Personal information is data that can be used to uniquely identify
            or contact a single person. You may choose to provide personal
            information to us when you use our Services. Because we change our
            features from time to time, the options you have to provide us with
            personal information also may change, but here are some examples of
            situations in which you may decide to provide personal information
            to us:
          </p>
          <ul>
            <li className='body-paragraph'>
              Contacting us for any reason, such as by email, including for
              technical support or customer service;
            </li>
            <li className='body-paragraph'>When making a payment; and</li>
            <li className='body-paragraph'>
              Using our Services when personal information is required for use
              or participation
            </li>
          </ul>
          <p className='body-paragraph'>
            The information we collect from you will vary depending on which of
            our Services you are using. However, the information you provide
            generally may include the following:
          </p>
          <ul>
            <li className='body-paragraph'>Name, email address, address, and phone number.</li>
            <li className='body-paragraph'>
              Information about your activity on and interaction with our
              Services (such as your IP address, the type of device you use,
              your geolocation, and your actions on the website); and
            </li>
            <li className='body-paragraph'>
              Communications you send to us (for example, when you ask for
              support, send us questions or comments, or report a problem).
            </li>
          </ul>
          <p className='body-paragraph'>
            Because we directly ask you to provide personal information in these
            cases, you often will be able to tell from our specific requests
            what types of information we receive from you.
          </p>
          <p className='body-paragraph'>We collect this information for reasons such as:</p>
          <ul>
            <li className='body-paragraph'>
              To understand your needs and provide you with better service;
            </li>
            <li className='body-paragraph'>
              For statistical purposes and analysis for management purposes to
              administer or improve our products and services;
            </li>
            <li className='body-paragraph'>
              Internal record keeping, and administrative purposes, and to
              inform you about our events, services or products, or other
              related information that we think would be of interest to you, as
              explained above;
            </li>
            <li className='body-paragraph'>
              To communicate marketing messages, newsletters, and details of our
              business or the businesses of carefully selected third parties
              which we think may be of interest to you by post or email or
              similar technology (you can inform us at any time if you no longer
              require marketing communications); and
            </li>
            <li className='body-paragraph'>
              From time to time, we may also use your information to contact you
              for market research purposes or to customize the website according
              to your interests
            </li>
          </ul>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Our Collection and Use of Non-Personal Information</strong>
          </p>
          <p className='body-paragraph'>
            We also collect non-personal information data in a form that does
            not permit direct association with any specific individual. We may
            collect, use and process non-personal information for service
            improving purposes.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Information Collected by Automated Means</strong>
          </p>
          <p className='body-paragraph'>
            We may obtain certain personal and non-personal information by
            automated means when you use our Services. The type of information
            we collect by automated means may vary, but generally includes
            technical information about your devices, such as IP address or
            another device identifier. The information we collect also may
            include usage information and statistics about your interaction with
            our Services. That information may include the specific page of our
            website that you visited, page views, time spent on a page, number
            of clicks, platform type, and other information about how you used
            our Service.
          </p>
          <p className='body-paragraph'>
            In cases where you use a mobile device to access our Service, we
            will receive information about the location of your device. In some
            cases, even if you are not using a mobile device, information about
            your general location may be discernable from your device’s IP
            address or the URLs we receive.
          </p>
          <p className='body-paragraph'>
            We use the information we collect by automated means to understand
            our Users and to determine their preferences. As a result, we can
            develop and deliver new or modified offerings, that are improved
            with our users’ preferences in mind.
          </p>
          <p className='body-paragraph'>
            Here are some of the types of automated data collection that may
            occur when you use our sites:
          </p>
          <p className='body-paragraph'>
            <u>Web Browsers</u>
          </p>
          <p className='body-paragraph'>
            When you visit our website, your browser automatically sends us your
            Internet protocol (“IP”) address so that the web pages you request
            can be sent to your computer or device. We use your IP address to
            determine additional information, such as whether the computer or
            device has ever been used to visit our site before, which site
            features were used most often, and how much time was spent on a
            page.
          </p>
          <p className='body-paragraph'>
            <u>Cookies</u>
          </p>
          <p className='body-paragraph'>
            A “cookie” is a text file placed on your mobile device’s internal
            memory when you visit our website which helps record how you
            navigate our website. Cookies allow our website/mobile app to
            recognize a particular device or browser and help us present you
            with tailored options based on the information stored from your
            previous visit. Please see our Cookie Policy for more information.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>ANTI-SPAM POLICY</strong>
          </p>
          <p className='body-paragraph'>
            Inner Find Corporation has zero-tolerance for unsolicited commercial
            e-mail (UCL) also referred to as junk email or spam. We strictly
            adhere and comply with the requirements of the CAN-SPAM Act of 2003
            (Controlling the Assault of Non-Solicited Pornography and Marketing
            Act), which prohibits us from sending unsolicited commercial e-mail.
            All users of our website and services who opt-in to receive
            electronic newsletters or other electronic communication from us
            will always be presented with the option to opt-out. We will desist
            from sending you further electronic messages as soon as we receive
            your opt-out request.
          </p>
          <p className='body-paragraph'>
            For further information or questions, kindly contact us on our email
            christinet@innerfind.ca.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>California Online Privacy Protection Act</strong>
          </p>
          <p className='body-paragraph'>
            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law’s
            reach stretches well beyond California to require any person or
            company in the United States (and conceivably the world) that
            operates websites collecting Personally Identifiable Information
            from California consumers to post a conspicuous privacy policy on
            its website stating exactly the information being collected and
            those individuals or companies with whom it is being shared. – See
            more at:{" "}
            <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/%23sthash.0FdRbT51.dpuf">
              http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
            </a>
          </p>
          <p className='body-paragraph'>
            According to CalOPPA, we agree and have implemented the following:
          </p>
          <ul>
            <li className='body-paragraph'>Users can visit our site anonymously.</li>
            <li className='body-paragraph'>
              Once this privacy policy is created, we will add a link to it on
              our home page or as a minimum, on the first significant page after
              entering our website.
            </li>
            <li className='body-paragraph'>
              Our Privacy Policy link includes the word ‘Privacy’ and can easily
              be found on the page specified above.
            </li>
            <li className='body-paragraph'>
              You will be notified of any Privacy Policy changes on our Privacy
              Policy Page
            </li>
          </ul>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Can change your personal information</strong>:
          </p>
          <p className='body-paragraph'>By calling us</p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>How does our site handle Do Not Track signals?</strong>
          </p>
          <p className='body-paragraph'>
            We honor Do Not Track signals and do not track, plant cookies, or
            use advertising when a Do Not Track (DNT) browser mechanism is in
            place.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>
              Does our site allow third-party behavioral tracking?
            </strong>
          </p>
          <p className='body-paragraph'>
            It’s also important to note that we do not allow third-party
            behavioral tracking
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>
              Legal Basis for Processing Personal Data under the EU General Data
              Protection Regulation (GDPR)
            </strong>
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Sharing of Information</strong>
          </p>
          <p className='body-paragraph'>
            We may share information about you as follows or as otherwise
            described in this Privacy Policy:
          </p>
          <ul>
            <li className='body-paragraph'>
              With vendors, customer system managers and other service providers
              who assist us in operating our website, conducting our business,
              or providing Services to you, so long as those third parties agree
              to keep this information confidential in accordance with European
              Union’s General Data Protection Regulation (“GDPR”);
            </li>
            <li className='body-paragraph'>
              In response to a request for information if we believe disclosure
              is in accordance with, or required by any applicable law,
              regulation, or legal process;
            </li>
            <li className='body-paragraph'>
              If we believe your actions are inconsistent with our Terms or to
              protect the rights, property, and safety of Inner Find Corporation
              or others;
            </li>
            <li className='body-paragraph'>
              In connection with, or during negotiations of, any merger, sale of
              company assets, financing or acquisition of all or a portion of
              our business by another company; and
            </li>
            <li className='body-paragraph'>With your consent or at your direction.</li>
          </ul>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Retention of Data</strong>
          </p>
          <p className='body-paragraph'>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes and enforce
            our legal agreements and policies.
          </p>
          <p className='body-paragraph'>
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer periods.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Transfer of Data</strong>
          </p>
          <p className='body-paragraph'>
            Your information, including Personal Data, may be transferred to and
            maintained on computers located outside of your state, province,
            country or other governmental jurisdiction where the data protection
            laws may differ from those of your jurisdiction.
          </p>
          <p className='body-paragraph'>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p className='body-paragraph'>
            We will take all the steps reasonably necessary to ensure that your
            data is treated securely and in accordance with this Privacy Policy
            and no transfer of your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of your data and other personal
            information.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Disclosure of Data</strong>
          </p>
          <p className='body-paragraph'>Business Transaction</p>
          <p className='body-paragraph'>
            If Inner Find Corporation is involved in a merger, acquisition or
            asset sale, your Personal Data may be transferred. We will provide
            notice before your Personal Data is transferred and becomes subject
            to a different Privacy Policy.
          </p>
          <p className='body-paragraph'>Legal Requirements</p>
          <p className='body-paragraph'>
            Inner Find Corporation may disclose your Personal Data in the good
            faith belief that such action is necessary to:
          </p>
          <ul>
            <li className='body-paragraph'>To comply with a legal obligation.</li>
            <li className='body-paragraph'>
              To protect and defend the rights or property of Inner Find
              Corporation.
            </li>
            <li className='body-paragraph'>
              To prevent or investigate possible wrongdoing in connection with
              the Service.
            </li>
            <li className='body-paragraph'>
              To protect the personal safety of Users of the Service or the
              public.
            </li>
            <li className='body-paragraph'>To protect against legal liability.</li>
          </ul>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Your Choices</strong>
          </p>
          <p className='body-paragraph'>
            In certain cases, you may have choices about how we use and disclose
            your personal information. For example, we will ask you before we
            use your personal information for any purpose incompatible with
            those purposes identified in this Notice.
          </p>
          <p className='body-paragraph'>
            You can also choose not to give us the information we request, as
            described in the “Our Collection and Use of Personal Information”
            section of this Notice. In some cases, if you decide not to provide
            information, we will not be able to provide you with access to some
            Services, or we may not be able to provide you with the Service,
            feature, or information you requested.
          </p>
          <p className='body-paragraph'>
            In some cases, you can limit the information we collect by automated
            means. You may also exercise and amend your choices by sending an
            email to us as specified in the “How to Contact Us” section below.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>How We Protect Personal Information</strong>
          </p>
          <p className='body-paragraph'>
            We have implemented measures designed to secure your personal
            information from accidental loss and from unauthorized access, use,
            alteration and disclosure. All information you provide to us is
            stored on secure servers behind firewalls.
          </p>
          <p className='body-paragraph'>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we do our best to protect your
            personal information, we cannot guarantee the security of your
            personal information transmitted to us; any transmission is at your
            own risk. Once we have received your information, we will use strict
            procedures and security features to try to prevent unauthorized
            access.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Links to Other Sites</strong>
          </p>
          <p className='body-paragraph'>
            Our Service may provide links to other websites or services. We do
            not control and are not responsible for the content or practices of
            these other websites or services. Our provision of such links does
            not constitute our endorsement of these third-party services, their
            content, their owners, or their practices. This Privacy Policy does
            not apply to these third-party services, which are subject to any
            privacy and other policies they may have. You should use caution and
            review the privacy policies of any third-party services that you
            visit from our Services or our products to learn more about their
            information practices, which may be significantly different from
            ours.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Children’s Privacy</strong>
          </p>
          <p className='body-paragraph'>
            We do not knowingly collect personally identifiable information from
            anyone under the age of 13. If you are a parent or guardian and you
            are aware that your Child has provided us with Personal Data, please
            contact us. If we become aware that we have collected Personal Data
            from Children without adult verification or parental consent, we
            will take the necessary steps to remove that information from our
            servers.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>Updates to Our Privacy Notice</strong>
          </p>
          <p className='body-paragraph'>
            This Privacy Notice may be updated periodically to you to reflect
            changes in our information practices or relevant laws. We will
            indicate at the top of the Notice when it was updated. Please review
            this Notice any time you access or use our sites to make sure you
            have reviewed the most recent version.
          </p>
          <p className='body-paragraph'>
            <strong className='body-heading my-3'>How to Contact Us</strong>
          </p>
          <p className='body-paragraph'>
            If you have any questions or comments about this Privacy Notice or
            if you would like us to update information, we have about you or
            your preferences, please contact us at
          </p>
          <p className='body-paragraph'>christinet@innerfind.ca.</p>{" "}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy

    
     

      