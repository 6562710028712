import React from 'react'
import "./BlogDetails.css"
import { useNavigate } from 'react-router-dom';

function BlogDetails({
    text="",
    blogsData,
    bannerImg="/images/creative/card-img.jpg"
}) {
    
  return (
    <div className='blog-details-container py-5 '>
        <div className='container d-flex flex-column align-items-center justify-content-center'>
            <img src={bannerImg} className='blog-thumbnail mb-5 img-fluid' alt='banner img'/>
            <div className='text-content w-75'>
                <div className='body-paragraph ' dangerouslySetInnerHTML={{__html:text}}></div>
            
                {
                    
                    <div className={`body-paragraph`} dangerouslySetInnerHTML={{ __html: blogsData.paragraph }}></div>

                }
                {/* {blogsData.map((item) => (
                  <>
                    <h4 className="content-title my-2 mt-5" style={{textTransform:"uppercase"}}>{item.heading}</h4>
                    <div className={`body-paragraph`} dangerouslySetInnerHTML={{ __html: item.paragraph }}></div>
                  </>
                ))} */}
               
           </div>
           <div className='bottom d-flex flex-column gap-4 flex-md-row align-items-center align-items-md-end justify-content-between w-75 py-4'>
                {/* <div className='social'>
                    <h5 className='color-blue' style={{fontWeight:"bold",}}>Share:</h5>
                    <div className='social-icon-box d-flex align-items-center justify-content-start'>
                        <img src='/images/icons/facebook-inner-icon.svg' className='social-icon'/>
                        <img src='/images/icons/linked-inner-icon.svg' className='social-icon'/>
                        <img src='/images/icons/twitter-inner-icon.svg' className='social-icon'/>
                        <img src='/images/icons/mail-inner-icon.svg' className='social-icon'/>
                        <img src='/images/icons/pintrest-inner-icon.svg' className='social-icon'/>
                    </div>
                </div> */}
                {/* <div className='date-container'>
                    <p className='m-0'><span style={{color:"#272525",fontSize:"1.1rem"}}>Published Date:</span> 26, Dec, 2023</p>
                </div> */}
           </div>
        {/* <div className='line-seprator'></div> */}
        </div>
    </div>
  )
}

export default BlogDetails