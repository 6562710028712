import React from 'react'
import Header from '../component/Header/Header'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'

function TermsOfService() {
  return (
    <div>
      <HeroBanner
        subHeading=""
        Heading="Terms Of Services"
        nextLineSpanHeading=""
        Paragraph=""
        bgImg="/images/creatives/plane-hero-bg-light.png"
        btn={false}
        sideLinks={false}
        bgColor=""
      />
      <div className="container term-of-service-container mt-5">
        <div class="elementor-widget-container">
          <p className='body-paragraph'>
            www.innerfind.ca is owned and operated by{" "}
             <strong>Inner Find Corporation</strong>, a limited liability company
            registered and existing under the laws of the province of Ontario.
          </p>
          <p className='body-paragraph'>
            Please read these Terms and Service, our Privacy Policy, and
            Disclaimer carefully before accessing or making use of our services.
            All users of our website and our products and services are
            conditioned upon acceptance of these Terms of Service, our Privacy
            Policy, and Disclaimer. We may require you to accept further terms
            that apply to certain features or services.
          </p>
          <p className='body-paragraph'>
            By clicking to indicate your acceptance or otherwise further access
            or use of any part of our services, you agree to be bound by these
            Terms of Service, our Privacy Policy, and Disclaimer.&nbsp; If you
            do not agree to these Terms, you are not allowed to use our
            Services. Any new or updated features or tools that are added to the
            current website and our services shall also be subject to these
            Terms of Service.&nbsp;
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>Definitions</strong>
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>“Our website” or “this website”</strong> – refers to
            www.innerfind.ca which includes: all pages, sub-pages, sub-domains,
            and any associated web-based application (collectively, “Website”)
            owned and operated by Inner Find Corporation.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>“We”, “Us”, and “Our”</strong>– shall be used
            interchangeably and shall refer to Inner Find Corporation, its
            owners, affiliates, and/or subsidiaries, and any person or entities
            associated with Inner Find Corporation.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>“Visitor”</strong>– is someone who merely browses this
            website but has not purchased our services.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>“Client”</strong>– is someone that has purchased our
            services.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>“User” or “Users” or “You”</strong>– is a collective
            identifier that refers to either a Visitor or Client.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>&nbsp;</strong>
             <strong className='body-heading my-3'>“Services”-</strong> shall collectively mean our reading
            &amp; healing services, our contents, and our website.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>“User Content”-</strong> collectively refers to all the data
            that users upload, transmit, create, or generate on or while using
            our Services. This includes all information a user sends to us.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>CHANGE TO THESE TERMS</strong>
          </p>
          <p className='body-paragraph'>
            We reserve the right, at our sole discretion and without prior
            notice, to make additions, modifications, remove, or change
            portions/all these Terms at any time. Changes will automatically be
            effective when posted unless we state otherwise. We urge Users to
            review the latest version of these Terms on this page any time they
            visit this website or make use of our Services. Your continued use
            of our Services without reading these Terms and their subsequent
            updates any time you use our Services means your acceptance of the
            latest version of these Terms.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>PRIVACY OF PERSONAL INFORMATION</strong>
          </p>
          <p className='body-paragraph'>
            Please see our privacy policy page to read more on how we use and
            safeguard your personal information.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>Eligibility</strong>
          </p>
          <p className='body-paragraph'>
            Our services are designed for individuals who are 18 years old and
            above.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>PAYMENTS AND PAYMENT PLANS </strong>
          </p>
          <p className='body-paragraph'>
            Fees for Inner Find Corporation services vary. You agree to pay the
            full amount indicated on the presented invoices. If you’re unclear
            about the payment terms at the time of purchase, please contact
            christinet@innerfind.ca for clarification. You will receive a
            receipt upon payment. Invoiced prices for the services specifically
            cover the specific service your requested, it does not include
            incurred expenses by the CLIENT for tasks required for the
            designated goals to be accomplished.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>DISCLAIMER AND WAIVER</strong>
          </p>
          <p className='body-paragraph'>
            All reading &amp; healing services and communication email or
            otherwise, delivered by Inner Find Corporation are meant to help you
            meet your desired needs in connection with your requested service.
          </p>
          <p className='body-paragraph'>
            However, our services are not professional mental health care or
            medical care or physical care. If at any point you feel
            psychologically stressed to the point that it is interfering with
            your ability to function adequately, we advise you seek the help you
            need in the form of a professional counsellor. By using our
            services, you confirm that you have read and agreed to each
            statement and that you wish to proceed:
          </p>
          <p className='body-paragraph'>
            You agree that our healing &amp; reading service does not substitute
            for professional mental health care or medical care or physical care
            and are not intended to diagnose, treat or cure any mental health or
            medical complications. You understand that we are not acting as a
            medical professional or a mental health counsellor.
          </p>
          <p className='body-paragraph'>
            You agree and understand that you are solely responsible for your
            well-being throughout any sessions, and subsequently, including my
            choices and decisions.
          </p>
          <p className='body-paragraph'>
            You understand that to the extent the services we offer involves
            your personal and mental care.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>Assumption of risk</strong>
          </p>
          <p className='body-paragraph'>
            You hereby agree that your use of this website and our services is
            done at your own risk. The information and content published on this
            website are strictly for informational purposes only. Your reliance
            on such is at your own risk. You understand that while we use great
            care to provide you with the best information and content possible,
            we make no representations or warranties of any kind, whether
            express or implied, about reliability, completeness, security, or
            accuracy of the information provided.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>ENTERTAINMENT AND Informational purposes only</strong>
          </p>
          <p className='body-paragraph'>
            The information and content provided on this website or through our
            services are strictly for Entertainment and informational purposes
            only, it is strictly personal and not in any professional capacity.
            The information and content provided are in no way meant to be a
            substitute for professional health or legal advice. If you require
            health or legal services, it is your sole responsibility to seek
            such service from a licensed medical services professional or
            attorney.
          </p>
          <p className='body-paragraph'>
            The information and content provided are in no way meant to be a
            substitute for psychological or medical advice. The information and
            services we provide do not serve to diagnose, treat, or provide a
            cure for any health condition you may be experiencing. If you
            require psychological or medical services, it is your sole
            responsibility to seek such service from a licensed mental health
            professional or doctor.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>Personal responsibility</strong>
          </p>
          <p className='body-paragraph'>
            You are obligated to provide us with accurate information about you
            and to use our services with your sole discretion. You acknowledge
            that you will only use our website and services voluntarily and that
            you are solely responsible for your actions, choices, results, and
            future outcomes. You accept full responsibility for the consequences
            of your decision use or not to use, of any information or services
            we provide, and you agree to use your discretion and to perform due
            diligence before implementing any of our suggestions, ideas, or
            recommendations.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>No guarantees</strong>
          </p>
          <p className='body-paragraph'>
            Our role is to give you support and assist in reaching your desired
            goals, but your success primarily depends on your effort,
            commitment, motivation, and implementation. You hereby affirm that
            you understand results differ for each Client. Each Client’s results
            primarily depend on his or her unique background, application,
            dedication, desire, motivation, implementation, actions, and other
            numerous factors. You fully understand and agree that there are no
            guarantees as to the specific result or outcome you can expect from
            using our services.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>Inner Find Corporation</strong>{" "}
             <strong className='body-heading my-3'>will not be liable for:</strong>
          </p>
          <ul>
            <li className='body-paragraph'>Any circumstance beyond our control.</li>
            <li className='body-paragraph'>Any loss, injury, or damage to a Client or their property.</li>
            <li className='body-paragraph'>
              Physical, medical, or psychiatric conditions developed during or
              after our services.
            </li>
          </ul>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>SESSION RECORDING</strong>
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>
              We do not allow recording session and don’t offer one.
            </strong>
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>
              ACCURACY, COMPLETENESS, AND TIMELINESS OF INFORMATION
            </strong>
          </p>
          <p className='body-paragraph'>
            This website may contain certain information which is not updated.
            We reserve the right to modify the contents of this website at any
            time without prior notice, but we have no obligation to update any
            information on our website. You agree that it is your responsibility
            to monitor changes to our website. We assume no responsibility if
            information made available on this website is not accurate,
            complete, or outdated.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>INTELLECTUAL PROPERTY; USER LIMITED LICENSE</strong>
          </p>
          <p className='body-paragraph'>
            Inner Find Corporation and our related Services: All contents
            provided to you through our Services are owned and licensed to Inner
            Find Corporation. Our contents and all associated Services are
            trademarked, copyrighted, and have obtained worldwide intellectual
            property rights. All rights are reserved. Inner Find Corporation,
            subject to your acceptance and abiding by these Terms grants you a
            limited license to use our Services. You agree not to use our
            Services in any way that will tarnish our good public reputation or
            bring us any liability. We reserve the right to terminate, ban, and
            seek further legal actions against you if you breach any of these
            Terms.
          </p>
          <p className='body-paragraph'>
            YOUR ATTEMPT TO DISRUPT, MANIPULATE, HIJACK, HACK OR INTERFERE WITH
            THE SERVICES OR OPERATIONS OF Inner Find Corporation WILL BE
            CONSIDERED A BREACH OF OUR OPERATIONAL POLICY OR VIOLATION OF
            CRIMINAL AND CIVIL LAWS.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>PROHIBITED USES</strong>
          </p>
          <p className='body-paragraph'>
            In addition to other prohibitions as set forth in the Terms of
            service, Users are prohibited from using the site or its content:
            (a) for any unlawful purpose; (b) to solicit others to perform or
            participate in any unlawful acts; (c) to violate any international,
            federal, provincial or state regulations, rules, laws, or local
            ordinances; (d) to infringe upon or violate our intellectual
            property rights or the intellectual property rights of others; (e)
            to harass, abuse, insult, harm, defame, slander, disparage,
            intimidate, or discriminate based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin, or disability; (f)
            to submit false or misleading information; (g) to upload or transmit
            viruses or any other type of malicious code that will or may be used
            in any way that will affect the functionality or operation of the
            Service or of any related website, other websites, or the Internet;
            (h) to collect or track the personal information of others; (i) to
            spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any
            obscene or immoral purpose; (k) to interfere with or circumvent the
            security features of the Service or any related website, other
            websites, or the Internet; and (l) to post any nude photos nor any
            type of solicitation from Clients.
          </p>
          <p className='body-paragraph'>
            We reserve the right to terminate your use of the Service or any
            related website for violating any of the prohibited uses.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>INDEMNIFICATION</strong>
          </p>
          <p className='body-paragraph'>
            You agree to indemnify, defend and hold harmless Inner Find
            Corporation and its owner(s), subsidiaries, affiliates, partners,
            officers, directors, agents, contractors, licensors, service
            providers, subcontractors, suppliers, interns, and employees,
            harmless from any claim or demand, including reasonable attorneys’
            fees, made by any third party due to or arising out of your breach
            of these Terms and Condition or the documents they incorporate by
            reference or your violation of any law or the rights of a
            third-party.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>COPYRIGHT NOTICES/COMPLAINTS</strong>
          </p>
          <p className='body-paragraph'>
            Inner Find Corporation takes its intellectual property rights and
            that of others seriously and we require you to do so. We respond
            promptly to alleged copyright infringement notices in compliance
            with the US Digital Millennium Copyright Act (“DMCA”), the
            E-Commerce Directive and associated legislation in the EU, and
            similar or equivalent other local laws that may apply. Inner Find
            Corporation reserves the right to terminate services of any User
            that infringes the intellectual property rights of others.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>USER COMMENTS, FEEDBACK, AND UNSOLICITED IDEAS</strong>
          </p>
          <p className='body-paragraph'>
            The comments on our website represent Clients’ experiences, their
            testimonials, and some insights about other people’s experiences
            with our website for illustration purposes only. The testimonials,
            comments, examples, and User contents are of actual clients and
            results they achieved by using our services, or they are comments
            from individuals who voluntarily speak of our character and/or the
            quality of our Services. They are not intended to guarantee or
            represent that our current or future Clients will achieve similar or
            the same results; rather, these testimonials and comments represent
            what is possible for illustrative purposes only.
          </p>
          <p className='body-paragraph'>
            We may require you to leave us feedback on certain features through
            a customer insights program. Your decision to leave feedback is
            optional but we would like you to do so. If we require feedback from
            you through a program, your feedback is subject to the guidelines of
            the specific program.
          </p>
          <p className='body-paragraph'>
            All feedback, information, or idea you send to us voluntarily is
            subject to our unsolicited ideas.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>AVAILABILITY OF THE SERVICES; WARRANTY DISCLAIMER</strong>
          </p>
          <p className='body-paragraph'>
            Our Services may be temporarily unavailable from time to time for
            maintenance or other reasons. Inner Find Corporation assumes no
            responsibility for any error, omission, interruption, deletion,
            defect, delay in operation or transmission, or communication
            failure. We reserve the right to suspend, withdraw, or restrict the
            availability of all or any part of our Services for business and
            operational reasons.
          </p>
          <p className='body-paragraph'>
            OUR SERVICES AND ALL CONTENT, MATERIALS, AND PRODUCTS ARE PROVIDED
            ON AN “AS IS” BASIS, WITH ALL “DEFECTS” AND “AS AVAILABLE” BASIS
            WITHOUT WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY, OR
            OTHERWISE. Inner Find Corporation DISCLAIMS ALL WARRANTIES, EXPRESS
            OR IMPLIED, INCLUDING WITHOUT LIMITATION, WARRANTIES OF TITLE,
            NON-INFRINGEMENT, AND IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, ACCURACY OF
            INFORMATIONAL CONTENT, AND ANY WARRANTIES ARISING OUT OF ANY USE OF
            OUR PRODUCTS AND SERVICES. Inner Find Corporation MAKES NO
            REPRESENTATION THAT THE SITE OR ANY CONTENT IS FREE FROM ERRORS,
            VIRUSES, BUGS, OR OTHER HARMFUL COMPONENTS. Inner Find Corporation
            MAKES NO REPRESENTATION OR WARRANTY THAT CONTENT PROVIDED THROUGH
            OUR SERVICE IS APPLICABLE OR APPROPRIATE FOR USE IN YOUR STATE OR
            COUNTRY.
          </p>
          <p className='body-paragraph'>
            BY USING OUR SERVICES, YOU ACKNOWLEDGE THAT YOU DO SO AT YOUR OWN
            RISK.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>LIMITATIONS; WAIVER OF LIABILITY</strong>
          </p>
          <p className='body-paragraph'>
            IN NO EVENT WILL Inner Find Corporation OR ITS OWNER(S), EMPLOYEES,
            OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT,
            CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, OR PUNITIVE DAMAGES, INCLUDING
            FOR ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF OUR
            SERVICES, CONTENT, OR ANY RELATED PRODUCT ACCESSED THROUGH OUR
            SERVICES, EVEN IF Inner Find Corporation HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED HEREIN, Inner Find Corporation LIABILITY TO YOU
            FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION
            WILL AT ALL TIMES BE LIMITED TO THE AMOUNT A CLIENT PAID FOR OUR
            SERVICES.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>GOVERNING LAW</strong>
          </p>
          <p className='body-paragraph'>
            These Terms and Conditions and any separate agreements whereby we
            provide you Services shall be governed by and construed by the laws
            of the province of Ontario.
          </p>
          <p className='body-paragraph'>
             <strong className='body-heading my-3'>CONTACT INFORMATION</strong>
          </p>
          <p className='body-paragraph'>
            Questions about these Terms of Service should be sent to us at
            christinet@innerfind.ca.
          </p>{" "}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default TermsOfService