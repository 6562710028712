import React from 'react'
import "./NewsCard.css"

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

function NewsCard(props) {
  const title = truncateText(props.title,50)
  return (
    <>
    
    {
      props.cardStyle ?
      <>
      <div className="news_card pb-3 row flex-lg-row " >
      
      <div className='col-lg-8 p-0'>
        <img src={props.img} className="card_img img-fluid h-100" alt="blog thumbnail" />
      </div>

      <div className='col-lg-4 px-lg-3 px-0 '>
        <div className="card_text  d-flex flex-column justify-content-evenly h-100 px-4 py-1 first-text-border">
          
        <div className='d-flex align-items-center gap-2 my-3'>
                {/* <p className='body-paragraph color mb-0' style={{color:"#B2B2B2"}}>Posted By: Inner Find</p>
                <p className='body-paragraph color mb-0' style={{color:"#B2B2B2"}}> | </p> */}
                <p className='body-paragraph  mb-0' style={{color:"#B2B2B2"}}>{props.date}</p>
            </div>
          <div className='main-content'>
            <h4 className={`body-heading mb-3`}>
            {title}
            </h4>
          </div>
            <p className='body-paragraph  mb-0 mb-4' >{props.content}</p>
          <button
              className='genral-btn-light mb-3'
              onClick={()=>{props.redirectionFunction(props.anchor)}}
            >Read More</button>
        </div>
      </div>
      
      </div>
      <div className='line d-flex flex-row px-4 mt-5 mb-4 pt-2'>
          <div className='line-seprator w-100'></div>
          <h5 className='sub-heading w-100 text-center'>LATEST POSTS</h5>
          <div className='line-seprator w-100'></div>
      </div>
    </>
        :
        <div className="news_card pb-3 h-100 " >
      <div className='p-0'>
          <img src={props.img} className="card_img img-fluid" alt="blog thumbnail" />
        </div>
        <div className=' p-0'>
          <div className="card_text  d-flex flex-column align-items-start justify-content-end  py-3">
            
          <div className='d-flex align-items-center gap-2 my-2'>
                  <p className='body-paragraph color mb-0' style={{color:"#B2B2B2"}}>Posted By: Inner Find</p>
                  <p className='body-paragraph color mb-0' style={{color:"#B2B2B2"}}> | </p>
                  <p className='body-paragraph  mb-0' style={{color:"#B2B2B2"}}>{props.date}</p>
              </div>
            <div className='main-content'>
              <h4 className={`body-heading mb-3`}>
              {title}
              </h4>
              <p className='body-paragraph mb-4' >{props.content}</p>
            </div>
            <button
                className='genral-btn-light mb-0 mt-2'
                onClick={()=>{props.redirectionFunction(props.anchor)}}
              >Read More</button>
          </div>
        </div>
        
      </div>
    }
    
    </>
   
  )
}

export default NewsCard