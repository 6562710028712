import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'
import PlainText from '../component/PlainText/PlainText'

function About() {
  return (
    <div> 
    <Helmet>
        <title> 
        
        </title>
        <meta 
        name='description' 
        content="" 
        />
    </Helmet>
  

    <HeroBanner 
    subHeading=""
    Heading=""
    nextLineSpanHeading=""
    Paragraph=""
    bgImg="/images/creatives/about-hero-bg-new.jpg"
    btn={false}
    sideLinks={false}
    bgVideo={false}
    bgImgLg={"/images/creatives/about-hero-bg-mob-new.jpg"}
    />

    
    <TextContent
    subHeading=""
    heading= "Hi, I’m Christine Therese"
    nextLineHeading=""
    // content={"My name is Christine Therese, and I am the founder and creator of Inner Findand I’m so glad you’re here.\n Inner Find is a journey that first began in 2016 when I decided to take the plunge, trust my spiritual gifts, and begin offering intuitive cup readings. I am passionate about, and extremely honoured to have the privilege to connect with people all over the world through both in person and remote readings.\nI love to create an honest and open space to connect, and to provide my clients with a sense of restored balance, clarity, and alignment."}
    content={"The founder and creator of Inner Find and I’m so glad you’re here. \n Inner Find began its journey in 2016 when I took the plunge, trusted my spiritual gifts, and started offering intuitive cup readings. \n I am passionate about and deeply honored to connect with people from all over the world through both in-person and remote readings.\n I love creating an honest and open space to connect, and to provide my clients with a sense of restored balance, clarity, and alignment."}
    btn={true}
    // postStylishText='Hi and welcome!'
    btnText={"Book An Appointment"}
    btnRefTel={true}
    shiftRow={true}
    img="/images/creatives/home-text-3-new.jpg"
    reviewContent={false}
    btnImg={false}
    fullWidthContent={true}
    fontDark={true}
    // headingBgScratch={true}
    smallHeading={true}
    />

    <TextContent
    subHeading=""
    heading= "My Story "
    headingColorChange=""
    fullWidthContent={true}
    content={"At age five, my intuitive experiences became an undeniable force in my life as I began having vivid and out-of-the-ordinary dreams that often came true as premonitions. Although I always had a strong intuition, it wasn’t until later in life, when I started doing energy readings, that I realized how profound my clairvoyant abilities were.\n After almost a decade in the insurance industry, I realized that I was not aligning with my true self. I could feel my intuition guiding me away from the corporate world and into the realm of energy work. Encouraged by friends and family who remarked on the astonishing accuracy of my intuitive insights, I decided to open my practice and began offering one-on-one readings.\n Since its inception in 2016, Inner Find has grown into a platform through which I help people across the globe gain clarity, heal, and find a true sense of inner freedom and peace.\n If you would like to learn more about what’s involved in a reading, please visit the Services page here."}
    // content={"At age five, my intuitive experiences became an undeniable force in my life as I began having vivid and out-of-the-ordinary dreams that often came true as premonitions. Although I always had a strong intuition, it wasn’t until later in life, when I started doing energy readings, that I realized how profound my clairvoyant abilities were.\n After almost a decade in the insurance industry, I realized that I was not aligning with my true self. I could feel my intuition guiding me away from the corporate world and into the realm of energy work. Encouraged by friends and family who remarked on the astonishing accuracy of my intuitive insights, I decided to open my practice and began offering one-on-one readings.\n Since its inception in 2016, Inner Find has grown into a platform through which I help people across the globe gain clarity, heal, and find a true sense of inner freedom and peace.\n If you would like to learn more about what’s involved in a reading, please visit the Services page here."}
    btn={false}
    shiftRow={false}
    img="/images/creatives/about-text-2.jpg"
    reviewContent={false}
    btnImg={false}
    fontDark={true}
    // scratchBefore={true}
    postSignature={"Christine Therese"}
    lessSpacedHeading={true}
    />

    <PlainText
        heading={"Get To Know More"}
        content={"Christine’s passion for counseling began with a traditional route when she studied at Sheridan College from 2007 to 2009, graduating as a Social Service Worker. In 2022, she furthered her education by completing courses in neuroanatomy at the University of Michigan.\n Her work was featured on Toronto’s hit radio station Z103.5 in 2020, when she was a guest on The Morning Show with Tony Monaco and Pina .\n In 2018, she obtained her Reiki Level 1 Certificate and was featured at the Metaphysical and Spiritual Expo of Toronto.\n Christine has been working as a professional intuitive energy reader for over 8 years. Click here to read reviews from her beloved clients.\n To reach out to Christine for a reading, or if you have any other questions about her services, you can email her at info@cupreadingswc.com."}
        // content={"Christine’s passion for counseling began with a traditional route when she studied at Sheridan College from 2007 to 2009, graduating as a Social Service Worker. In 2022, she furthered her education by completing courses in neuroanatomy at the University of Michigan. \n Her work was featured on Toronto’s hit radio station Z103.5 in 2020, when she was a guest on The Morning Show with Tony Monaco and Pina. \n In 2018, she obtained her Reiki Level 1 Certificate and was featured at the Metaphysical and Spiritual Expo of Toronto. \n Christine has been working as a professional intuitive energy reader for over 8 years. Click here to read reviews from her beloved clients.\n To reach out to Christine for a reading, or if you have any other questions about her services, you can email her at info@cupreadingswc.com."}
        btnText={"Book An Appointment"}
        btn={true}
        btnRefTel={true}
        bgImg={"/images/creatives/plain-text-bg-about.jpg"}
        bgImgLg={"/images/creatives/plain-text-bg-about-mob.png"}
        wideContent={true}
        // headingBg={"/images/icons/plain-text-heading-bg.svg"}
        removePaddingHomeCard={false}
        lessSpacedHeading={true}
      />
    <Footer/>
</div>
  )
}

export default About