import React,{useState,useEffect} from "react";
import "./NewsList.css";
import NewsCard from "./NewsCard";
import LatestNews from "../LatestNews/LatestNews"
import { useNavigate } from "react-router-dom";
import axios from "axios"
import {Spinner} from "react-bootstrap"

const dumyBlogs = [
 
  {
    blog_creative: "/images/creatives/blog-thumb-1.jpg",
    main_heading: "Discovering Your Inner Self",
    content:"Finding your inner self is a journey of self-discovery and self-awareness.....",
    date:"JAN, 2024",
    slug_url: "1",
    catagory:"RESOURCES",
  },
  {
    blog_creative: "/images/creatives/blog-thumb-2.jpg",
    main_heading: "Understanding Intuition",
    content:"Intuition is the ability to understand or know something without the need...",
    date:"JAN, 2024",
    slug_url: "2",
    catagory:"RESOURCES",
  },
  {
    blog_creative: "/images/creatives/blog-thumb-3.jpg",
    main_heading: "Use Your Intuition",
    content:"Using your intuition involves tapping into your inner wisdom and making decisions...",
    date:"JAN, 2024",
    slug_url: "3",
    catagory:"RESOURCES",
  },
  {
    blog_creative: "/images/creatives/blog-thumb-1.jpg",
    main_heading: "Discovering Your Inner Self",
    content:"Finding your inner self is a journey of self-discovery and self-awareness.....",
    date:"JAN, 2024",
    slug_url: "1",
    catagory:"RESOURCES",
  },
  
];



let defaultBlogObj ;

function NewsList() {


  // const itemsPerPage = 3;
  // const [currentPage, setCurrentPage] = useState(1);

  const [blogs, setBlogs] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const[firstBlog,setFirstBlog] = useState()

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let blogAPICalled = false;

  useEffect(() => {
    // getAllBlogsListing();
    setBlogs(dumyBlogs)
  }, []);

  const getAllBlogsListing = () => {
    if (blogAPICalled) return;
    blogAPICalled = true;

    setLoading(true);
    axios
      .get("/blogs", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data_Blogs = [];
          let Updated_recent_blogs = [];
          const [first , ...otherBlog] = res.data.data;
          setFirstBlog(first)
          otherBlog.forEach((item) => {
            Updated_data_Blogs.push({
              id: item.blog_id,
              main_heading: item.title,
              paragraph: item.brief_paragraph,
              logo: "/favicon.png",
              entity: "Creative Squad",
              date: item.published_date,
              slug_url: item.slug_url,
              category: item.category,
              blog_creative: item.blog_image,
            });
          });
          otherBlog.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          setBlogs(Updated_data_Blogs);
          defaultBlogObj = Updated_data_Blogs;
          setLoading(false);
        }
          
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);

    navigate(`/artical/${slug}`);
  };

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const currentNewsData = blogs.slice(startIndex, endIndex);

  // const handlePageChange = (page) => {
  //   if(page <= (Math.ceil(blogs.length/itemsPerPage)))
  //   {
  //     setCurrentPage(page);
  //   }
  //   else{
  //     return
  //   }
  // }

  return (
    <>
    {loading ? 
        (<div className="w-100 text-center mb-5">
          <Spinner
            style={{ color: "#baa29c", width: "120px", height: "120px" }}
          />
        </div>):
        <>
        <div className={`news-container `}>
          <div className="standard-padding-space ">
          <div className="container">
          <div className="row">
            
            {blogs.map((ele,ind) => (
              <div 
                key={ele.id} 
                className={` ${ind==0?"col-12":"col-lg-4"} mx-sm-auto gy-4 mb-4`}
              >
                <NewsCard 
                 img={ele.blog_creative}
                 title={ele.main_heading}
                 content={ele.content}
                 anchor={ele.slug_url}
                 redirectionFunction={redirectUserToBlog}
                 date={ele.date}
                 catagory={ele.catagory}
                 cardStyle={ind==0? true: false}
                />
              </div>
            ))}
          </div>
          
          {/* <div className="page-btn-container my-5 d-flex align-items-center justify-content-md-center justify-content-center  gap-3">
                 {[...Array(Math.ceil(blogs.length / itemsPerPage)).keys()].map((page) => {
                    if (page + 1 <= 3) {
                      return (
                        <button
                          key={page + 1}
                          className={currentPage === page + 1 ? 'active' : ''}
                          onClick={() => handlePageChange(page + 1)}
                        >
                          {page + 1}
                        </button>
                      );
                    } else {
                      if(page < 4 )
                        {
                          return (
                          <button key="next" onClick={() => handlePageChange(currentPage + 1)}>
                            <img src="/images/icons/next-btn-icon.svg" className="next-icon" alt="->" />
                          </button>
                          );    
                        }
                        else
                        {
                          return (<></>)
                        }
                    }
                  })}
            </div> */}
        </div>
        </div>
      </div>
      </>}
    </>
  );
}

export default NewsList;
