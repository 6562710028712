import React, { useState } from 'react';
import "./HomeGreyContainer.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import { useNavigate } from 'react-router-dom';

const serviceData = [
    {
      "icon": "/images/icons/service-card-icon-1.svg",
      "title": "Personalized Sessions",
      "para": "Each session is tailored to your unique needs and goals, ensuring a truly meaningful and impactful experience."
    },
    {
     "icon": "/images/icons/service-card-icon-2.svg",
      "title": "Proven Experience",
      "para": "With over 8 years in the field, I bring deep knowledge and a dedication to ongoing personal and professional growth."
    },
    {
      "icon": "/images/icons/service-card-icon-3.svg",
      "title": "Open Communication",
      "para": "Expect clear, kind, and supportive interactions that foster a positive and collaborative relationship."
    },
    {
      "icon": "/images/icons/service-card-icon-4.svg",
      "title": "Holistic Approach",
      "para": "Combining intuitive insights with practical strategies, I offer a well-rounded approach to support and empower your growth."
    },
    {
      "icon": "/images/icons/service-card-icon-5.svg",
      "title": "Confidential and Non-Judgmental Guidance",
      "para": "I offer insights with empathy and respect, creating a safe, supportive space where you feel truly understood."
    }
  ]
  

function HomeGreyContainer() {
    const navigate = useNavigate()
    return (
        <div className='px-lg-0 '>
            <div className='HomeGreyContainer-container standard-padding-space mt-3 container px-lg-5 px-4'>
                <div className=' pt-2'>
                <div className='text-content text-center'>
                        {/* <img src='/images/icons/scratch-white.svg' alt='scratch icon' /> */}
                        <h3 className='body-heading py-2 mt-3 less-spaced-heading'>Work With Me</h3>
                    </div>
                <div className=' my-5 row align-items-center mt-3'>
                    
                    <div className='col-lg-6 px-4'>
                    

                    {/* <Swiper
                        spaceBetween={30} 
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Navigation]}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        breakpoints={{
                            280: {
                                slidesPerView: 1,
                            },
                            500: {
                                slidesPerView: 2,
                            },
                            992: {
                                slidesPerView: 3, 
                            }
                        }}
                    >
                        <SwiperSlide>
                            <div className='card-box d-flex flex-column align-items-center px-5 py-5 justify-content-center'>
                                <img src='/images/icons/ambission-icon.svg' alt='icon' className='card-icons' />
                                <h3 className='body-heading mb-3' style={{ color: "white", fontSize: "28px" }}>Personalized Sessions</h3>
                                <p className='body-paragraph text-center' style={{ color: "white" }}>Each session is tailored to your unique needs and goals, ensuring a truly meaningful and impactful experience.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box d-flex flex-column align-items-center px-5 py-5 justify-content-center'>
                                <img src='/images/icons/balance-icon.svg' alt='icon' className='card-icons' />
                                <h3 className='body-heading mb-3' style={{ color: "white", fontSize: "28px" }}>Proven Experience</h3>
                                <p className='body-paragraph text-center' style={{ color: "white" }}>With over 8 years in the field, I bring deep knowledge and a dedication to ongoing personal and professional growth.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box d-flex flex-column align-items-center px-5 py-5 justify-content-center'>
                                <img src='/images/icons/balance-icon.svg' alt='icon' className='card-icons' />
                                <h3 className='body-heading mb-3' style={{ color: "white", fontSize: "28px" }}>Open Communication</h3>
                                <p className='body-paragraph text-center' style={{ color: "white" }}>Expect clear, kind, and supportive interactions that foster a positive and collaborative relationship.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box d-flex flex-column align-items-center px-5 py-5 justify-content-center'>
                                <img src='/images/icons/Holistic-Approach.svg' alt='icon' className='card-icons' />
                                <h3 className='body-heading mb-3' style={{ color: "white", fontSize: "28px" }}>Holistic Approach</h3>
                                <p className='body-paragraph text-center' style={{ color: "white" }}>Combining intuitive insights with practical strategies, I offer a well-rounded approach to support and empower your growth
                                    .</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-box d-flex flex-column align-items-center px-5 py-5 justify-content-center'>
                                <img src='/images/icons/Confidential.svg' alt='icon' className='card-icons' />
                                <h3 className='body-heading mb-3' style={{ color: "white", fontSize: "28px" }}>Confidential and Non-Judgmental Guidance</h3>
                                <p className='body-paragraph text-center' style={{ color: "white" }}>I offer insights with empathy and respect, creating a safe, supportive space where you feel truly understood.</p>
                            </div>
                        </SwiperSlide>
                    
                    </Swiper>
                    <div className="swiper-button-next" style={{ color: "white" }}></div>
                    <div className="swiper-button-prev" style={{ color: "white" }}></div>
                */}
    <img src='/images/creatives/services-side-img.jpg' alt='side img' className='img-fluid d-lg-none d-block my-2 mb-4'/>
                    <div className='row justify-content-center'>
                        {
                            serviceData.map((ele)=>{
                                return(
                                    <DropDonwCard 
                                        icon={ele.icon}
                                        title={ele.title}
                                        para={ele.para}
                                    />
                                
                                )
                            })
                        }
                    </div>

                    <div className='d-flex align-items-center justify-content-center mt-4'>
                        <button className='hero-genral-btn' onClick={()=>{navigate("/services")}}>
                            Explore Services
                        </button>
                    </div>



                </div>
                <div className='col-lg-6 d-none d-lg-flex align-items-center justify-content-center px-5 py-xxl-3 py-0 '>
                        <img src='/images/creatives/services-side-img.png' alt='side img' className='img-fluid'/>
                    </div>

                </div>
            </div>
            </div>
        </div>
    );
}

const DropDonwCard =({
    icon,
    title="",
    para=""
})=>{
    const [dropOpen , setDropOpen] = useState(false)
    return(
        <>
        <div 
        className='card-box d-flex align-items-center px-1 py-lg-3 py-0 justify-content-center col-12'
        onClick={() => {
        setDropOpen((prev) => {
          return !prev;
        });
      }}
    >
        <img src={icon} alt='icon' className='card-icons' />
        <div className='text-start'>
            <h3 className='body-heading mb-0' style={{ color: "#4C5957" }}>{title}</h3>
            <p className={`body-paragraph mb-0 hiden-content  ${dropOpen ? "hiden-content-show":"hiden-content-hide"}`} style={{ color: "white" }}>{para}</p>
        </div>
        <img src={dropOpen ? '/images/icons/dropdown-less-icon-grey.png':'/images/icons/dropdown-up-icon-grey.png'} className='eye-icon' alt='dropdown-icon'/>
    </div>
    <div className='line-seprator'></div>
    </>
    )
}

export default HomeGreyContainer;
